import { Alert, Badge, Card } from 'antd';
import {
  ClockCircleOutlined, CloseOutlined, ReloadOutlined, UserOutlined,
} from '@ant-design/icons';
import { Workspace } from '../../../api';
import { getDayDisplay } from '../../../common/utils/dates';
import PricingPlan from '../../types/PricingPlan';
import plans from '../../utils/plans';
import ManageSubscriptionButton from '../ManageSubscriptionButton';

interface SubscriptionInfoProps {
  workspace: Workspace;
}

function getPlanName(planId: PricingPlan['id']) {
  const plan = plans.find((p) => p.id === planId);
  return plan ? plan.name : 'Free';
}

function getTitle(workspace: Workspace) {
  if (workspace.inTrial) {
    return 'Free Trial';
  }

  return getPlanName(workspace.subscription?.planId || '');
}

const TRIAL_EXPIRY_DAYS = 14;

function getTrialExpiryDate(workspace: Workspace): Date {
  const expiryDate = new Date(workspace.createdAt);
  expiryDate.setDate(expiryDate.getDate() + TRIAL_EXPIRY_DAYS);
  return expiryDate;
}

export default function SubscriptionInfo(props: SubscriptionInfoProps) {
  const { workspace } = props;

  if (!workspace.hasTrialOrActiveSubscription) {
    return (
      <Alert
        type="error"
        message={(
          <span className="fw-bold">No active subscription</span>
        )}
        description="You don't have an active subscription. Please subscribe to a plan to continue using the app."
      />
    );
  }

  return (
    <Card size="small">
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="m-0">{getTitle(workspace)}</h2>
        <div className="d-flex align-items-center justify-content-between gap-4">
          {
            workspace.subscription?.isActive && (
              <Badge text="Active" color="#52c41a" className="fw-bold" />
            )
          }
          <ManageSubscriptionButton>Manage</ManageSubscriptionButton>
        </div>
      </div>
      {
        !workspace.inTrial && workspace.subscription && (
          <div
            className="d-flex align-items-center justify-content-between text-muted mt-3"
            style={{ fontSize: 17 }}
          >
            {
              workspace.subscription.renewOnExpiry ? (
                <div className="d-flex align-items-center gap-2">
                  <ReloadOutlined />
                  <span>
                    {getDayDisplay(new Date(workspace.subscription.expiresAt))}
                  </span>
                </div>
              ) : (
                <div className="d-flex align-items-center gap-2">
                  <CloseOutlined />
                  <span>
                    expires on
                    {' '}
                    {getDayDisplay(new Date(workspace.subscription.expiresAt))}
                  </span>
                </div>
              )
            }
          </div>
        )
      }
      {
        workspace.inTrial && (
          <div
            className="d-flex align-items-center justify-content-between text-muted mt-3"
            style={{ fontSize: 17 }}
          >
            <div className="d-flex align-items-center justify-content-between gap-2">
              <UserOutlined />
              <span>
                unlimited seats in trial
              </span>
            </div>
            <div className="d-flex align-items-center gap-2">
              <ClockCircleOutlined />
              <span>
                {`ends ${getDayDisplay(getTrialExpiryDate(workspace))}`}
              </span>
            </div>
          </div>
        )
      }
    </Card>
  );
}
