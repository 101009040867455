// eslint-disable-next-line import/prefer-default-export
export function get(key, obj) {
    // Nested key getter for objects
    var keys = key.split('.');
    // @ts-ignore
    return keys.reduce(function (acc, k) {
        if (acc && acc[k]) {
            return acc[k];
        }
        return undefined;
    }, obj);
}
