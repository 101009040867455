"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationResolution = exports.ConversationHandlerType = exports.ConversationEngagementType = exports.ConversationStatus = void 0;
var ConversationStatus;
(function (ConversationStatus) {
    /**
     * The conversation is open, meaning that it hasn't been resolved yet.
     */
    ConversationStatus["OPEN"] = "open";
    /**
     * The conversation is closed, meaning that it has been resolved.
     */
    ConversationStatus["CLOSED"] = "closed";
})(ConversationStatus || (exports.ConversationStatus = ConversationStatus = {}));
var ConversationEngagementType;
(function (ConversationEngagementType) {
    /**
     * The conversation was initiated by an AI agent or a bot.
     */
    ConversationEngagementType["PROACTIVE"] = "proactive";
    /**
     * The conversation was initiated by a user.
     */
    ConversationEngagementType["REACTIVE"] = "reactive";
})(ConversationEngagementType || (exports.ConversationEngagementType = ConversationEngagementType = {}));
var ConversationHandlerType;
(function (ConversationHandlerType) {
    /**
     * The conversation is handled by an AI agent.
     */
    ConversationHandlerType["AI"] = "ai";
    /**
     * The conversation is handled by a human agent.
     */
    ConversationHandlerType["HUMAN"] = "human";
})(ConversationHandlerType || (exports.ConversationHandlerType = ConversationHandlerType = {}));
var ConversationResolution;
(function (ConversationResolution) {
    /**
     * The conversation was resolved.
     */
    ConversationResolution["OPEN"] = "open";
    /**
     * The conversation was resolved.
     */
    ConversationResolution["RESOLVED"] = "resolved";
    /**
     * The conversation was partially resolved.
     */
    ConversationResolution["PARTIALLY_RESOLVED"] = "partially-resolved";
    /**
     * The conversation was unresolved.
     */
    ConversationResolution["UNRESOLVED"] = "unresolved";
})(ConversationResolution || (exports.ConversationResolution = ConversationResolution = {}));
