import { Store } from 'pullstate';
import Macro from '@zupport/types/models/Macro';

type MacrosState = {
  macros: Macro[];
  initialized: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const MacrosStore = new Store<MacrosState>({
  macros: [],
  initialized: false,
});
