"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationIntentDiscoveryMode = exports.ConversationIntentResolution = exports.ConversationIntentStatus = exports.ConversationIntentType = void 0;
var ConversationIntentType;
(function (ConversationIntentType) {
    ConversationIntentType["TECHNICAL_ISSUE"] = "technical_issue";
    ConversationIntentType["QUESTION"] = "question";
})(ConversationIntentType || (exports.ConversationIntentType = ConversationIntentType = {}));
var ConversationIntentStatus;
(function (ConversationIntentStatus) {
    ConversationIntentStatus["OPEN"] = "open";
    ConversationIntentStatus["IN_PROGRESS"] = "in-progress";
    ConversationIntentStatus["CLOSED"] = "closed";
})(ConversationIntentStatus || (exports.ConversationIntentStatus = ConversationIntentStatus = {}));
var ConversationIntentResolution;
(function (ConversationIntentResolution) {
    ConversationIntentResolution["UNRESOLVED"] = "unresolved";
    ConversationIntentResolution["RESOLVED"] = "resolved";
})(ConversationIntentResolution || (exports.ConversationIntentResolution = ConversationIntentResolution = {}));
var ConversationIntentDiscoveryMode;
(function (ConversationIntentDiscoveryMode) {
    ConversationIntentDiscoveryMode["AI"] = "ai";
    ConversationIntentDiscoveryMode["MANUAL"] = "manual";
})(ConversationIntentDiscoveryMode || (exports.ConversationIntentDiscoveryMode = ConversationIntentDiscoveryMode = {}));
