import { ReactNode } from 'react';

interface WebsiteUserInfoItemProps {
  icon: ReactNode;
  label: string;
  value: ReactNode;
}

export default function WebsiteUserInfoItem(props: WebsiteUserInfoItemProps) {
  const { icon, label, value } = props;

  return (
    <div className="d-flex align-items-center justify-content-between">
      <span className="d-flex gap-1 align-items-center text-secondary">
        {icon}
        {label}
      </span>
      <span>{value}</span>
    </div>
  );
}
