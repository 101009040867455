import { Card } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PricingPlan from '../../../billing/types/PricingPlan';
import cn from '../../../common/utils/cn';
import Button from '../../../common/components/Button';
import { pluralize } from '../../../common/utils/strings';

export interface PricingPlanCardProps {
  plan: PricingPlan;
  onSubscribe: (pricingId: string) => void;
  subscribeLoading?: boolean;
  subscribeDisabled?: boolean;
  active?: boolean;
  action?: 'subscribe' | 'upgrade' | 'downgrade' | 'resume' | 'current';
  onUpgrade?: (pricingId: string) => void;
  upgradeLoading?: boolean;
  onDowngrade?: (pricingId: string) => void;
  downgradeLoading?: boolean;
  onResume?: (pricingId: string) => void;
  resumeLoading?: boolean;
}

function formatPrice(price: number) {
  if (price === 0) {
    return 'Free';
  }

  return `$${price}`;
}

interface FeatureItemProps {
  label: string;
  included: boolean;
}

function FeatureItem(props: FeatureItemProps) {
  const { label, included } = props;
  return (
    <div className={cn('d-flex justify-content-between', !included && 'text-muted')}>
      <span>{label}</span>
      <span className={cn('color-primary', !included && 'text-muted')}>
        {
          included ? <CheckOutlined /> : <CloseOutlined />
        }
      </span>
    </div>
  );
}

export default function PricingPlanCard(props: PricingPlanCardProps) {
  const {
    plan,
    onSubscribe,
    subscribeLoading,
    subscribeDisabled,
    active,
    action,
    onUpgrade,
    upgradeLoading,
    onDowngrade,
    downgradeLoading,
    onResume,
    resumeLoading,
  } = props;

  return (
    <Card>
      <h2 className="text-center">{plan.name}</h2>
      <div className="d-flex justify-content-center align-items-end">
        <h1 className="text-center m-0">{formatPrice(plan.price)}</h1>
        {
          !!plan.price && (
            <h3 className="text-center m-0 mb-1 text-secondary">
              /month
            </h3>
          )
        }
      </div>
      <h4 className="mt-5">Includes</h4>
      <FeatureItem
        label={(plan.seatsCount || 0) < Infinity ? `${plan.seatsCount} seats` : 'Unlimited seats'}
        included
      />
      <FeatureItem
        label={`${plan.aiRepliesCount.toLocaleString('en-US')} free AI replies/month`}
        included={plan.aiRepliesCount > 0}
      />
      <FeatureItem
        label={`${pluralize(plan.helpCentersCount, 'help center')}`}
        included
      />
      <FeatureItem
        label="Multilingual help center"
        included={!!plan.multilingualHelpCentersEnabled}
      />
      <FeatureItem
        label="AI Issue Resolution"
        included
      />
      <FeatureItem
        label="FAQs"
        included
      />
      <FeatureItem
        label="Proactive Engagement"
        included={plan.proactiveEngagementEnabled}
      />
      <FeatureItem
        label="Premium Support"
        included={!!plan.premiumSupport}
      />
      <FeatureItem
        label="AI Integration Support"
        included={!!plan.aiIntegrationSupport}
      />
      <div className="d-flex align-items-center justify-content-center mt-5 gap-2">
        {
          action === 'subscribe' && (
            <Button
              onClick={() => {
                if (!subscribeDisabled) {
                  onSubscribe(plan.id);
                }
              }}
              loading={subscribeLoading}
              type="primary"
              color="primary"
              disabled={active}
              block
            >
              Subscribe
            </Button>
          )
        }
        {
          action === 'upgrade' && (
            <Button
              type="primary"
              color="primary"
              disabled={active}
              onClick={() => {
                if (onUpgrade) {
                  onUpgrade(plan.id);
                }
              }}
              loading={upgradeLoading}
              block
            >
              Upgrade
            </Button>
          )
        }
        {
          action === 'downgrade' && (
            <Button
              type="primary"
              color="primary"
              disabled={active}
              onClick={() => {
                if (onDowngrade) {
                  onDowngrade(plan.id);
                }
              }}
              loading={downgradeLoading}
              block
            >
              Downgrade
            </Button>
          )
        }
        {
          action === 'resume' && (
            <Button
              type="primary"
              color="primary"
              onClick={() => {
                if (onResume) {
                  onResume(plan.id);
                }
              }}
              loading={resumeLoading}
              block
            >
              Resume
            </Button>
          )
        }
        {
          action === 'current' && (
            <Button
              type="primary"
              color="primary"
              disabled
              block
            >
              Current Plan
            </Button>
          )
        }
      </div>
    </Card>
  );
}
