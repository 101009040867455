import {
  ReactNode, useCallback,
  useEffect,
  useState,
} from 'react';
import { Popover, PopoverProps } from 'antd';
// @ts-ignore
import getCaretCoordinates from 'textarea-caret';

export interface InputPopoverProps extends Pick<PopoverProps, 'overlayInnerStyle'> {
  input: HTMLTextAreaElement;
  items: string[];
  onSearch: (search: string) => void;
  renderPopupContent: (setActiveItem: (activeItem: number) => void) => ReactNode;
  onActiveItemChange?: (activeItem: number) => void;
  onSelect?: (item: string) => void;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function insertItem(input: HTMLTextAreaElement, text: string, search: string) {
  const start = (input.selectionStart || 0) - search.length - 1;

  const newPosition = start + text.length;
  input.setSelectionRange(newPosition, newPosition);
  input.focus();
}

export default function InputPopover(props: InputPopoverProps) {
  const {
    input,
    items = [],
    onSearch,
    renderPopupContent,
    onActiveItemChange,
    overlayInnerStyle,
    onSelect,
    open,
    onOpenChange,
  } = props;
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [activeItem, setActiveItem] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (onActiveItemChange) {
      onActiveItemChange(activeItem);
    }
  }, [activeItem, onActiveItemChange]);

  useEffect(() => {
    // Handle the input change and detect #
    const handleChange = () => {
      const { value } = input;
      const cursorPosition = input.selectionStart || 0;

      // Check if the value contains # and filter commands
      const match = value.slice(0, cursorPosition).match(/#(\w*)$/);

      if (match) {
        if (onOpenChange) {
          onOpenChange(true);
        }
        onSearch(match[1]);
        setSearch(match[1]);

        // Get the caret position to position the popup
        const { top, left } = getCaretCoordinates(input, cursorPosition);
        setPopupPosition({ top, left });
      } else if (onOpenChange) {
        onOpenChange(false);
      }
    };

    input.addEventListener('input', handleChange);

    return () => {
      input.removeEventListener('input', handleChange);
    };
  }, []);

  const itemsJSON = JSON.stringify(items);

  useEffect(() => {
    if (activeItem >= items.length && items.length > 0) {
      setActiveItem(items.length - 1);
    }
  }, [itemsJSON, activeItem]);

  const selectItem = useCallback((text: string) => {
    insertItem(input, text, search);
    const start = (input.selectionStart || 0) - search.length - 1;
    const end = input.selectionEnd || 0;
    const { value } = input;

    const result = value.slice(0, start) + text + value.slice(end);
    if (onSelect) {
      onSelect(result);
    }
  }, [onSelect]);

  useEffect(() => {
    if (open) {
      const listener = (e: KeyboardEvent) => {
        const isUpArrow = e.key === 'ArrowUp';
        const isKeyDown = e.key === 'ArrowDown';

        if (isUpArrow) {
          e.preventDefault();
          // Handle up arrow key
          setActiveItem((prev) => {
            if (prev === 0) {
              return items.length - 1;
            }

            return Math.max(prev - 1, 0);
          });
        } else if (isKeyDown) {
          e.preventDefault();
          // Handle down arrow key
          setActiveItem((prev) => {
            if (prev === items.length - 1) {
              return 0;
            }

            return Math.min(prev + 1, items.length - 1);
          });
        } else if (e.key === 'Enter') {
          e.preventDefault();
          selectItem(items[activeItem]);

          if (onOpenChange) {
            onOpenChange(false);
          }
        }
      };

      input.addEventListener('keydown', listener);

      return () => {
        input.removeEventListener('keydown', listener);
      };
    }

    return () => {};
  }, [activeItem, open, itemsJSON, onSearch, selectItem]);

  return (
    <Popover
      content={renderPopupContent(setActiveItem)}
      open={open}
      onOpenChange={onOpenChange}
      placement="topLeft"
      align={{
        offset: [
          popupPosition.left - 5,
          popupPosition.top - input.offsetHeight - input.scrollTop + 10,
        ],
      }}
      trigger="click"
      arrow={false}
      overlayInnerStyle={overlayInnerStyle}
    />
  );
}
