import React, { useEffect, useState } from 'react';
import {
  Layout, Menu, MenuProps,
} from 'antd';
import {
  AuditOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  GlobalOutlined,
  InboxOutlined, LockOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PicCenterOutlined,
  PlusOutlined,
  SettingOutlined,
  SwapOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Link, Outlet, useLocation, useMatch,
} from 'react-router-dom';
import { asset } from '../../common/utils/assets';
import useIsMobile from '../../common/hooks/useIsMobile';
import useActiveWorkspaceSlug from '../../workspace/hooks/useActiveWorkspaceSlug';
import useAuth from '../../auth/hooks/useAuth';
import { setActiveWorkspace, WorkspaceStore } from '../../workspace/state';
import usePermissions from '../../permissions/hooks/usePermissions';
import PlugConnectedIcon from '../../common/components/Icons/PlugConnectedIcon';
import SegmentIcon from '../../common/components/Icons/SegmentIcon';
import LightBulbFilamentIcon from '../../common/components/Icons/LightBulbFilamentIcon';
import Button from '../../common/components/Button';
import SlidersIcon from '../../common/components/Icons/SlidersIcon';

export default function AppLayout() {
  const workspaceSlug = useActiveWorkspaceSlug();
  const workspaces = WorkspaceStore.useState((s) => s.workspaces);
  const match = useMatch('/:workspaceSlug/:page/*');
  const isMobile = useIsMobile();
  const [collapsed, setCollapsed] = useState(true);
  const auth = useAuth();
  const permissions = usePermissions();

  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [location, isMobile]);

  const items: MenuProps['items'] = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: (
        <Link to={`/${workspaceSlug}/dashboard`} style={{ color: 'inherit' }}>
          Dashboard
        </Link>
      ),
    },
    {
      key: 'inbox',
      icon: <InboxOutlined />,
      label: (
        <Link to={`/${workspaceSlug}/inbox`} style={{ color: 'inherit' }}>
          Inbox
        </Link>
      ),
    },
  ];

  if (permissions.assistants.manage) {
    items.push({
      key: 'assistants',
      icon: <UserOutlined />,
      label: (
        <Link to={`/${workspaceSlug}/assistants`} style={{ color: 'inherit' }}>
          AI Assistants
        </Link>
      ),
    });
  }

  if (permissions.messenger.widget.configurations) {
    items.push({
      key: 'messenger-widget-configurations',
      icon: (
        <span className="ant-menu-item-icon">
          <SlidersIcon />
        </span>
      ),
      label: (
        <Link
          to={`/${workspaceSlug}/messenger/widget/configurations`}
          style={{ color: 'inherit' }}
        >
          Messenger Widget Configurations
        </Link>
      ),
    });
  }

  if (permissions.websites.any) {
    items.push({
      key: 'website',
      icon: <GlobalOutlined />,
      label: (
        <Link to={`/${workspaceSlug}/website/settings/setup`} style={{ color: 'inherit' }}>
          Website
        </Link>
      ),
    });
  }

  items.push({
    key: 'help-centers',
    icon: <PicCenterOutlined />,
    label: (
      <Link to={`/${workspaceSlug}/help-centers`} style={{ color: 'inherit' }}>
        Help Centers
      </Link>
    ),
  });

  if (permissions.featureRequests.manage) {
    items.push({
      key: 'feature-requests',
      icon: <LightBulbFilamentIcon />,
      label: (
        <Link to={`/${workspaceSlug}/feature-requests/feature-requests`} style={{ color: 'inherit' }}>
          Feature Requests
        </Link>
      ),
    });
  }

  return (
    <Layout>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          height: '100vh',
        }}
        width={isMobile ? 220 : 220}
        collapsedWidth={isMobile ? 0 : 80}
        className="app-layout-sidebar"
      >
        <Link className="app-layout-sidebar-header" to={`/${workspaceSlug}/inbox`}>
          <img
            className="app-layout-sidebar-header-logo-text"
            src={asset('/static/img/zupport-logo-text-black.png')}
            alt="Zupport Logo"
          />
          <img
            className="app-layout-sidebar-header-logo"
            src={asset('/static/img/zupport-blue-logo.png')}
            alt="Zupport Logo"
          />
        </Link>
        <div className="app-layout-sidebar-content">
          <Menu
            mode="inline"
            defaultSelectedKeys={[match?.params.page || 'inbox']}
            items={items}
            style={{ flex: 1 }}
          />
          <Menu
            mode="inline"
            selectedKeys={workspaceSlug ? [workspaceSlug] : []}
            items={[
              {
                key: 'settings',
                icon: <SettingOutlined />,
                label: 'Settings',
                children: [
                  {
                    key: 'user-settings',
                    icon: <UserOutlined />,
                    label: (
                      <Link to="/settings/general" style={{ color: 'inherit' }}>
                        User
                      </Link>
                    ),
                    children: [
                      {
                        key: 'settings/general',
                        label: (
                          <Link to="/settings/general" style={{ color: 'inherit' }}>
                            Settings
                          </Link>
                        ),
                        icon: <SettingOutlined />,
                      },
                      {
                        key: 'settings/security',
                        label: (
                          <Link to="/settings/security" style={{ color: 'inherit' }}>
                            Security
                          </Link>
                        ),
                        icon: <LockOutlined />,
                      },
                    ],
                  },
                  ...(
                    permissions.workspace.any ? [{
                      key: 'workspace-settings',
                      icon: <AuditOutlined />,
                      label: 'Workspace',
                      children: [
                        {
                          key: 'workspace/settings/general',
                          label: (
                            <Link to={`/${workspaceSlug}/settings/general`} style={{ color: 'inherit' }}>
                              Settings
                            </Link>
                          ),
                          icon: <SettingOutlined />,
                        },
                        {
                          key: 'workspace/settings/members',
                          label: (
                            <Link to={`/${workspaceSlug}/settings/members`} style={{ color: 'inherit' }}>
                              Members
                            </Link>
                          ),
                          icon: <UserOutlined />,
                        },
                        {
                          key: 'workspace/settings/billing',
                          label: (
                            <Link to={`/${workspaceSlug}/settings/billing`} style={{ color: 'inherit' }}>
                              Billing
                            </Link>
                          ),
                          icon: <CreditCardOutlined />,
                        },
                        {
                          key: 'workspace/settings/integrations',
                          label: (
                            <Link to={`/${workspaceSlug}/settings/integrations`} style={{ color: 'inherit' }}>
                              Integrations
                            </Link>
                          ),
                          icon: <PlugConnectedIcon />,
                        },
                        {
                          key: 'workspace/settings/user-segments',
                          label: (
                            <Link to={`/${workspaceSlug}/settings/user-segments`} style={{ color: 'inherit' }}>
                              User Segments
                            </Link>
                          ),
                          icon: <SegmentIcon />,
                        },
                      ],
                    }] : []
                  ),
                  {
                    key: 'workspace-switcher',
                    icon: <SwapOutlined />,
                    label: 'Switch Workspace',
                    children: [
                      ...workspaces.map((w) => ({
                        key: w.slug,
                        label: w.name,
                        onClick: () => {
                          setActiveWorkspace(w).then(() => {
                            window.location.href = `/${w.slug}/inbox`;
                          });
                        },
                      })),
                      {
                        type: 'divider',
                        style: {
                          borderColor: 'var(--app-content-separator-border-color)',
                        },
                      },
                      {
                        key: 'create-workspace',
                        label: (
                          <Link to="/workspaces/create" style={{ color: 'inherit' }}>
                            Create Workspace
                          </Link>
                        ),
                        icon: <PlusOutlined />,
                      },
                    ],
                  },
                  {
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    label: 'Logout',
                    onClick: auth.logout,
                  },
                ],
              },
            ]}
          />
        </div>
      </Layout.Sider>
      <Layout className="app-layout-content">
        <Button
          shape="round"
          className="app-layout-mobile-sidebar-open-button mobile-only"
          onClick={() => {
            setCollapsed((prev) => !prev);
          }}
        >
          {
            collapsed ? (
              <MenuFoldOutlined />
            ) : (
              <MenuUnfoldOutlined />
            )
          }
        </Button>
        {
          !collapsed && (
            <div
              className="app-layout-sidebar-overlay"
              onClick={() => setCollapsed(true)}
              style={{
                left: 220,
              }}
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              aria-label="Close Sidebar"
            />
          )
        }
        <Layout.Content className="app-layout-content-container">
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
