import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import AuthPageLayout from '../../layout/components/AuthPageLayout';
import useQuery from '../../common/hooks/useQuery';
import api from '../../api';
import useUser from '../hooks/useUser';
import SignUpForm from '../components/SignUpForm';
import useRequest from '../../common/hooks/useRequest';
import { setActiveWorkspace } from '../../workspace/state';
import useAuth from '../hooks/useAuth';

export default function InvitePage() {
  const user = useUser();
  const { authenticate } = useAuth();
  const { workspaceUserId, token } = useParams();
  const navigate = useNavigate();

  const { data: invite, error } = useQuery(
    async () => {
      if (!token) {
        throw new Error('Token is required');
      }

      if (!workspaceUserId) {
        throw new Error('Workspace user ID is required');
      }

      return api.workspaces.users.invite.get(workspaceUserId, token);
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const request = useRequest(
    async () => {
      if (!workspaceUserId) {
        throw new Error('Missing data, please try again');
      }

      if (!token) {
        throw new Error('Missing data, please try again');
      }

      await authenticate();
      return api.workspaces.users.invite.accept(workspaceUserId, token);
    },
    {
      onSuccess: async (wu) => {
        api.workspaces.get(wu.workspaceId).then((workspace) => {
          setActiveWorkspace(workspace);
          navigate(`/${workspace.slug}/inbox`);
        }).catch(() => {
          notification.error({
            message: 'Failed to load workspace',
          });
        });
      },
    },
  );

  return (
    <AuthPageLayout>
      <h1 className="mb-4">Invite</h1>
      {
        error && (
          <p>
            Invalid invite link, please try again.
          </p>
        )
      }
      {
        invite && (
          <div>
            <p>
              You have been invited to join a new workspace
            </p>
            {
              !user ? (
                <SignUpForm
                  invite={invite}
                  inviteToken={token}
                />
              ) : (
                <div>
                  {
                    user.email !== invite.email ? (
                      <p>
                        The email invitation doesn&apos;t match your account email.
                        {' '}
                        Please sign up with the email you were invited with.
                      </p>
                    ) : (
                      <p>
                        Redirecting, please wait...
                      </p>
                    )
                  }
                </div>
              )
            }
          </div>
        )
      }
    </AuthPageLayout>
  );
}
