import useActiveWorkspace from './useActiveWorkspace';
import PricingPlan from '../../billing/types/PricingPlan';
import plans, { free } from '../../billing/utils/plans';

export default function useActiveWorkspacePlan() {
  const workspace = useActiveWorkspace();
  let plan: PricingPlan = free;

  if (workspace && workspace.subscription?.isActive) {
    plan = plans.find((p) => p.id === workspace.subscription?.planId) || plan;
  }

  return plan;
}
