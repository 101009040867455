import { useCallback, useMemo } from 'react';
import useActiveWorkspaceUser from '../../workspace/hooks/useActiveWorkspaceUser';
import { WorkspaceUserRole } from '../../api';

interface UsePermissions {
  assistants: {
    manage: boolean;
  };
  websites: {
    any: boolean;
  };
  featureRequests: {
    manage: boolean;
  };
  messenger: {
    widget: {
      configurations: boolean;
    },
  },
  workspace: {
    any: boolean;
  };
  conversations: {
    reassign: {
      // Can reassign any conversation
      any: boolean;
      // Can reassign own conversations
      own: boolean;
    };
    reply: {
      any: boolean;
      own: boolean;
    };
  };
}

export default function usePermissions(): UsePermissions {
  const user = useActiveWorkspaceUser();

  const is = useCallback((role: WorkspaceUserRole | WorkspaceUserRole[]) => {
    if (!user) {
      return false;
    }

    if (Array.isArray(role)) {
      return role.includes(user.role);
    }

    return user.role === role;
  }, [user]);

  return useMemo(() => {
    const ADMIN_OWNER = is([WorkspaceUserRole.ADMIN, WorkspaceUserRole.OWNER]);
    const ADMIN_OWNER_DEV = is([
      WorkspaceUserRole.ADMIN,
      WorkspaceUserRole.OWNER,
      WorkspaceUserRole.SOFTWARE_ENGINEER,
    ]);
    const ANY_BUT_DEV = is([
      WorkspaceUserRole.ADMIN,
      WorkspaceUserRole.OWNER,
      WorkspaceUserRole.CUSTOMER_SUPPORT_AGENT,
    ]);

    return {
      assistants: {
        manage: ADMIN_OWNER,
      },
      websites: {
        any: ADMIN_OWNER_DEV,
      },
      featureRequests: {
        manage: ADMIN_OWNER_DEV,
      },
      messenger: {
        widget: {
          configurations: ADMIN_OWNER_DEV,
        },
      },
      workspace: {
        any: ADMIN_OWNER,
      },
      conversations: {
        reassign: {
          any: ADMIN_OWNER,
          own: ANY_BUT_DEV,
        },
        reply: {
          any: ADMIN_OWNER,
          own: ANY_BUT_DEV,
        },
      },
    };
  }, [is]);
}
