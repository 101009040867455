import { Outlet, useMatch } from 'react-router-dom';
import { SettingOutlined, UnlockOutlined } from '@ant-design/icons';
import AppSubPageLayout from '../../layout/components/AppSubPageLayout';

export default function UserSettingsPageLayout() {
  const match = useMatch('/settings/:key');

  return (
    <AppSubPageLayout
      pages={[
        {
          key: 'general',
          title: 'General',
          path: 'general',
          icon: <SettingOutlined />,
        },
        {
          key: 'security',
          title: 'Security',
          path: 'security',
          icon: <UnlockOutlined />,
        },
      ]}
      activePageKey={match?.params.key || 'general'}
      content={<Outlet />}
    />
  );
}
