import { useState } from 'react';
import { ConversationWithMetadata } from '@zupport/types/models/Conversation';
import useQuery from '../../../common/hooks/useQuery';
import api from '../../../api';
import Spinner from '../../../common/components/Spinner';
import ConversationsList from '../../../inbox/components/ConversationsList';

interface GlobalWebsiteUserModalContentProps {
  websiteUserId: string;
}

export default function GlobalWebsiteUserModalContent(props: GlobalWebsiteUserModalContentProps) {
  const { websiteUserId } = props;
  const [conversations, setConversations] = useState<ConversationWithMetadata[]>([]);
  const userQuery = useQuery(async () => api.websiteUsers.get(websiteUserId));

  const [paramsEngagementMode, setParamsEngagementMode] = useState('incoming');
  const [paramsStatus, setParamsStatus] = useState('open');

  const loadingUser = userQuery.loading;

  return (
    <div>
      {
        loadingUser && (
          <Spinner center />
        )
      }
      <div
        style={{
          height: 'calc(100dvh - 57px)',
        }}
      >
        {
          userQuery.data && (
            <ConversationsList
              conversations={conversations}
              setConversations={setConversations}
              paramsEngagementMode={paramsEngagementMode}
              setParamsEngagementMode={setParamsEngagementMode}
              paramsStatus={paramsStatus}
              setParamsStatus={setParamsStatus}
              conversationParticipantId={userQuery.data.conversationParticipantId}
            />
          )
        }
      </div>
    </div>
  );
}
