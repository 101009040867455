import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import SimpleBar from 'simplebar-react';
import { Mentions, message, Modal } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Macro, { MacroContext } from '@zupport/types/models/Macro';
import { interpolate } from '@zupport/utils/interpolate';
import InputPopover, { InputPopoverProps, insertItem } from '../../../common/components/InputPopover';
import Divider from '../../../common/components/Divider';
import Button from '../../../common/components/Button';
import useMacros from '../../../macros/hooks/useMacros';

interface ConversationInputMacrosPopupProps extends Pick<InputPopoverProps, 'input'> {
  onSelect?: (item: string, search: string) => void;
  openKey?: number;
  context?: MacroContext;
}

interface MacroItemProps {
  content: string;
  active: boolean;
  onMouseEnter: () => void;
  onSelect: () => void;
  onEditClick: () => void;
  showEdit: boolean;
  onDeleteClick: () => void;
}

function MacroItem(props: MacroItemProps) {
  const {
    content,
    active,
    onMouseEnter,
    onSelect,
    onEditClick,
    showEdit,
    onDeleteClick,
  } = props;

  return (
    <div
      className="position-relative"
      onMouseEnter={onMouseEnter}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect();
      }}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      style={{
        cursor: 'pointer',
        background: active ? '#f0f0f0' : 'transparent',
        borderRadius: 6,
      }}
    >
      <div
        style={{
          padding: '6px 10px',
        }}
      >
        {content}
      </div>
      {
        showEdit && (
          <div
            className="d-flex gap-1"
            style={{
              position: 'absolute',
              right: 4,
              bottom: 4,
            }}
          >
            <Button
              style={{
                maxHeight: 20,
              }}
              icon={<DeleteOutlined style={{ fontSize: 12 }} />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDeleteClick();
              }}
            />
            <Button
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onEditClick();
              }}
              style={{
                maxHeight: 20,
              }}
              icon={<EditOutlined style={{ fontSize: 12 }} />}
            />
          </div>
        )
      }
    </div>
  );
}

export default function ConversationInputMacrosPopup(props: ConversationInputMacrosPopupProps) {
  const {
    input, onSelect, openKey, context = {},
  } = props;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [activeItem, setActiveItem] = useState(0);
  const [dialogOpen, setDialogOpen] = useState('never');
  const [editMacro, setEditMacro] = useState<Macro>();
  const [editModeOn, setEditModeOn] = useState(false);
  const [deleteMacro, setDeleteMacro] = useState<Macro>();
  const macros = useMacros(open);
  const { items } = macros;
  const [newMacroText, setNewMacroText] = useState('');
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const scrollableNodeRef = useRef<HTMLDivElement>(null);

  const filteredItems = items.filter((macro) => macro.content.includes(search));
  const filteredItemsJSON = JSON.stringify(filteredItems);

  useEffect(() => {
    if (openKey) {
      setOpen(true);
    }
  }, [openKey]);

  const selectMacro = useCallback((value: string) => {
    const item = interpolate(value, context);

    insertItem(input, item, search);

    if (onSelect) {
      onSelect(item, search);
    }

    setOpen(false);
    setSearch('');
  }, [search, onSelect]);

  useEffect(() => {
    if (itemsContainerRef.current && scrollableNodeRef.current) {
      const { children } = itemsContainerRef.current;
      const activeChild = children[activeItem] as HTMLElement;

      if (activeChild) {
        const position = activeChild.offsetTop
          - scrollableNodeRef.current.scrollTop
          + activeChild.offsetHeight;
        const isHigher = position < activeChild.offsetHeight;
        const isInView = !isHigher && position < scrollableNodeRef.current.offsetHeight;

        if (!isInView) {
          if (isHigher) {
            if (activeItem === 0) {
              // Move to the top
              scrollableNodeRef.current.scrollTop = 0;
            } else {
              // Move up just enough to show the active item
              scrollableNodeRef.current.scrollTop = activeChild.offsetTop - 5;
            }
          } else if (activeItem === filteredItems.length - 1) {
            scrollableNodeRef.current.scrollTop = scrollableNodeRef.current.scrollHeight;
          } else {
            // Move down just enough to show the active item
            scrollableNodeRef.current.scrollTop = activeChild.offsetTop
                - scrollableNodeRef.current.offsetHeight
                + activeChild.offsetHeight + 5;
          }
        }
      }
    }
  }, [activeItem, filteredItemsJSON]);

  const renderPopupContent = (changeItem: (item: number) => void) => (
    <div>
      <div className="d-flex align-items-center justify-content-between px-3 py-2">
        <h3 className="m-0 fw-bold">Macros</h3>
        <div className="d-flex gap-1">
          <Button
            size="small"
            type={editModeOn ? 'default' : 'link'}
            onClick={() => { setEditModeOn((prev) => !prev); }}
          >
            {editModeOn ? 'Cancel' : 'Edit'}
          </Button>
          <Button
            size="small"
            onClick={() => {
              setDialogOpen('open');
              setOpen(false);
            }}
          >
            Create Macro
          </Button>
        </div>
      </div>
      <Divider className="m-0" />
      <SimpleBar
        style={{
          minWidth: 500,
          maxHeight: 400,
          overflow: 'auto',
        }}
        scrollableNodeProps={{
          ref: scrollableNodeRef,
        }}
      >
        <div ref={itemsContainerRef} className="p-2">
          {
            filteredItems.map((macro, index) => (
              <MacroItem
                key={macro._id}
                content={macro.content}
                active={activeItem === index}
                onMouseEnter={() => { changeItem(index); }}
                onSelect={() => {
                  selectMacro(macro.content);
                }}
                onEditClick={() => {
                  setEditMacro(macro);
                  setNewMacroText(macro.content);

                  setTimeout(() => {
                    setDialogOpen('open');
                  }, 100);
                  setOpen(false);
                }}
                showEdit={editModeOn}
                onDeleteClick={() => {
                  setDeleteMacro(macro);
                  setOpen(false);
                }}
              />
            ))
          }
        </div>
        {
          filteredItems.length === 0 && (
            <p className="m-0 text-center pb-3">
              No macros found, feel free to add your own!
            </p>
          )
        }
      </SimpleBar>
    </div>
  );

  return (
    <>
      <InputPopover
        input={input}
        open={open}
        onOpenChange={setOpen}
        items={filteredItems.map((macro) => macro.content)}
        onSearch={setSearch}
        renderPopupContent={renderPopupContent}
        onActiveItemChange={setActiveItem}
        overlayInnerStyle={{
          padding: 0,
        }}
        onSelect={selectMacro}
      />
      {
        dialogOpen !== 'never' && (
          <Modal
            open={dialogOpen === 'open'}
            onCancel={() => {
              setDialogOpen('closed');

              setTimeout(() => {
                setEditMacro(undefined);
                setNewMacroText('');
              }, 100);
            }}
            title={editMacro ? 'Edit Macro' : 'Create New Macro'}
            onOk={() => {
              if (!editMacro) {
                macros.add.submit({ content: newMacroText }).then(() => {
                  setDialogOpen('closed');
                  setNewMacroText('');
                  setOpen(true);
                });
              } else {
                macros.edit.submit(editMacro._id, { content: newMacroText }).then(() => {
                  setDialogOpen('closed');
                  setNewMacroText('');
                  setOpen(true);
                  setEditMacro(undefined);
                });
              }
            }}
            okText={editMacro ? 'Save' : 'Create Macro'}
            okButtonProps={{
              loading: editMacro ? macros.edit.loading : macros.add.loading,
            }}
          >
            <Mentions
              variant="outlined"
              placeholder="Type your macro here..."
              prefix={['{']}
              value={newMacroText}
              onChange={(value) => setNewMacroText(value)}
              options={[
                {
                  label: 'User First Name',
                  value: '{user.firstName}}',
                },
                {
                  label: 'User Last Name',
                  value: '{user.lastName}}',
                },
                {
                  label: 'User Full Name',
                  value: '{user.name}}',
                },
                {
                  label: 'User Email',
                  value: '{user.email}}',
                },
                {
                  label: 'My First Name',
                  value: '{me.firstName}}',
                },
                {
                  label: 'My Last Name',
                  value: '{me.lastName}}',
                },
                {
                  label: 'My Full Name',
                  value: '{me.name}}',
                },
                {
                  label: 'My Email',
                  value: '{me.email}}',
                },
              ]}
              rows={5}
            />
            <small>
              Type
              {' '}
              <b>{'{'}</b>
              {' '}
              to add a variable.
            </small>
          </Modal>
        )
      }
      {
        editModeOn && (
          <Modal
            title="Delete Macro"
            open={!!deleteMacro}
            onCancel={() => {
              setDeleteMacro(undefined);
              setOpen(true);
            }}
            okText="Delete Macro"
            okButtonProps={{
              loading: macros.remove.loading,
              disabled: macros.remove.loading,
            }}
            onOk={() => {
              if (deleteMacro) {
                macros.remove.submit(deleteMacro._id).then(() => {
                  message.success('Macro deleted successfully');
                  setOpen(true);
                  setDeleteMacro(undefined);
                });
              }
            }}
          >
            This action is irreversible. Are you sure you want to delete this macro?
          </Modal>
        )
      }
    </>
  );
}
