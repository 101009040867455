import { Form, Input, notification } from 'antd';
import User from '@zupport/types/models/User';
import api, { WorkspaceUser } from '../../api';
import useAuth from '../hooks/useAuth';
import useRequest from '../../common/hooks/useRequest';
import Button from '../../common/components/Button';
import { getMarketingParams } from '../../common/utils/tracking';
import { setActiveWorkspace } from '../../workspace/state';

interface SignUpFormProps {
  invite?: WorkspaceUser;
  inviteToken?: string;
}

export default function SignUpForm(props: SignUpFormProps) {
  const { invite, inviteToken } = props;

  const auth = useAuth();
  const [form] = Form.useForm();
  const request = useRequest(async (values: Pick<User, 'firstName' | 'lastName' | 'email' | 'password'>) => {
    const result = await auth.signUp({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      params: getMarketingParams(),
      inviteToken,
    });

    if (invite) {
      const workspaceId = result.workspaceIds[0];
      if (workspaceId) {
        try {
          const workspace = await api.workspaces.get(workspaceId);
          await setActiveWorkspace(workspace);
          await auth.authenticate();
          window.location.href = `/${workspace.slug}/dashboard`;
        } catch (e) {
          window.location.href = '/';
        }
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to load workspace',
        });
        // As a fallback, redirect to the home page
        window.location.href = '/';
      }
    }
  }, {
    form,
  });

  return (
    <Form<Pick<User, 'firstName' | 'lastName' | 'email' | 'password'>>
      form={form}
      layout="vertical"
      onFinish={request.submit}
      initialValues={{
        firstName: invite?.firstName || '',
        lastName: invite?.lastName || '',
        email: invite?.email || '',
      }}
    >
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          { required: true, message: 'Please enter your first name' },
          { pattern: /^[a-zA-Z]+$/, message: 'First name must contain only letters' },
        ]}
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          { required: true, message: 'Please enter your last name' },
          { pattern: /^[a-zA-Z]+$/, message: 'Last name must contain only letters' },
        ]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input
          placeholder="Email"
          disabled={!!invite}
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please enter your password' },
          { min: 8, message: 'Password must be at least 8 characters' },
          { max: 32, message: 'Password must be at most 32 characters' },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password' },
          ({ getFieldValue }) => ({
            async validator(_, value) {
              if (value && getFieldValue('password') !== value) {
                throw new Error('The passwords do not match');
              }
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          loading={request.loading}
          block
        >
          {
            !invite ? 'Sign Up' : 'Accept Invitation'
          }
        </Button>
      </Form.Item>
    </Form>
  );
}
