import { Form, Input, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthPageLayout from '../../layout/components/AuthPageLayout';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api, { WorkspaceUserRole } from '../../api';
import useAuth from '../hooks/useAuth';
import { setActiveWorkspace } from '../../workspace/state';

type Data = { verificationCode: string };

export default function VerifyEmailPage() {
  const auth = useAuth();
  const [form] = Form.useForm<Data>();
  const navigate = useNavigate();
  const request = useRequest(async (data: Data) => {
    const user = await api.users.verifyEmail(data.verificationCode);
    auth.setUser(user);

    try {
      const response = await api.workspaces.my();
      const [workspace] = response.items;

      if (!workspace) {
        notification.error({
          message: 'Error loading workspace',
          description: 'Please try again later',
        });
        window.location.href = '/';
      } else {
        const [, workspaceUser] = await setActiveWorkspace(workspace);
        // Trigger authenticate again in order to fetch all the related data
        // required for the app to work properly
        await auth.authenticate();
        if (workspaceUser.role === WorkspaceUserRole.OWNER) {
          navigate(`/${workspace.slug}/website/settings/setup`);
        } else {
          navigate(`/${workspace.slug}/inbox`);
        }
      }
    } catch (e) {
      window.location.href = '/';
    }

    return user;
  });

  const resendRequest = useRequest(async () => api.users.resendVerificationEmail(), {
    onSuccess: () => {
      notification.success({
        message: 'Verification Email Sent',
        description: 'Please check your email for the verification code',
      });
    },
  });

  return (
    <AuthPageLayout>
      <h1 className="mb-4">
        Verify Email
      </h1>
      <p>
        Please enter the verification code sent to your email address.
      </p>
      <Form<Data>
        form={form}
        onFinish={request.submit}
      >
        <Form.Item
          name="verificationCode"
          rules={[{ required: true, message: 'Please enter the verification code' }]}
        >
          <Input placeholder="Verification Code" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={request.loading}
            block
          >
            Verify Email
          </Button>
        </Form.Item>
        <div className="d-flex justify-content-between">
          <Button
            type="link"
            style={{ marginLeft: -24 }}
            onClick={resendRequest.submit}
            loading={resendRequest.loading}
          >
            Resend Email
          </Button>
          <Button
            onClick={auth.logout}
            style={{ marginRight: -24 }}
            type="link"
          >
            Logout
          </Button>
        </div>
      </Form>
    </AuthPageLayout>
  );
}
