import axios, { AxiosHeaders, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { Socket, io } from 'socket.io-client';
import ConversationMessage from '@zupport/types/models/ConversationMessage';
import Conversation from '@zupport/types/models/Conversation';
import GlobalConversationsMetadata from '@zupport/types/models/GlobalConversationsMetadata';
import Auth from './services/Auth';
import { REACT_APP_API_URL } from '../config/env';
import Users from './services/Users';
import Workspace from './models/Workspace';
import Paginated from './types/Paginated';
import Workspaces from './services/Workspaces';
import Websites from './services/Websites';
import Website, { ProactiveEngagementSequence } from './models/Website';
import Assistant, { AssistantType, AssistantData } from './models/Assistant';
import Assistants from './services/Assistants';
import FAQ from './models/FAQ';
import Conversations from './services/Conversations';
import ConversationMessages from './services/ConversationMessages';
import ConversationParticipants from './services/ConversationParticipants';
import Agents from './services/Agents';
import ConversationMetadata from './models/ConversationMetadata';
import WorkspaceUser, { WorkspaceUserRole, WorkspaceUserData } from './models/WorkspaceUser';
import WebsiteUser from './models/WebsiteUser';
import WebsiteModule from './models/WebsiteModule';
import ConversationIntents from './services/ConversationIntents';
import Billing from './services/Billing';
import HelpCenter, { HelpCenterVerificationStatus } from './models/HelpCenter';
import HelpCenters, { HelpCenterPatchData } from './services/HelpCenters';
import ContentWebsites from './services/ContentWebsites';
import ContentWebsitePage, { ContentWebsitePageCrawlingStatus } from './models/ContentWebsitePage';
import HelpCenterArticle, {
  HelpCenterArticleData,
  HelpCenterArticleStatus,
  HelpCenterArticleContent,
} from './models/HelpCenterArticle';
import LocalizedValue from './models/LocalizedValue';
import Upload, { UploadType, PublicUpload } from './models/Upload';
import Uploads from './services/Uploads';
import Integrations from './services/Integrations';
import UserSegment from './models/UserSegment';
import UserSegments from './services/UserSegments';
import SlackChannel from './models/SlackChannel';
import FeatureRequest from './models/FeatureRequest';
import FeatureRequests from './services/FeatureRequests';
import SearchResult from './models/SearchResult';
import Reports from './services/Reports';
import MessengerWidgetConfigurations from './services/MessengerWidgetConfigurations';
import WebsiteUsers from './services/WebsiteUsers';
import Macros from './services/Macros';
import Notes from './services/Notes';

export const WORKSPACE_LOCAL_STORAGE_KEY = 'workspace';

export function getActiveWorkspace(): Workspace | undefined {
  const workspace = localStorage.getItem(WORKSPACE_LOCAL_STORAGE_KEY);
  if (workspace) {
    return JSON.parse(workspace);
  }

  return undefined;
}

export interface ServerToClientEvents {
  'conversation:created': (conversation: Conversation) => void;
  'conversation:updated': (conversation: Conversation) => void;
  'conversation:message:created': (message: ConversationMessage) => void;
  'conversation:metadata:updated': (conversation: Conversation, metadata: ConversationMetadata) => void;
  'conversation:participant:metadata:updated': (metadata: GlobalConversationsMetadata) => void;
}

export interface ClientToServerEvents {}

class Api {
  axios: AxiosInstance;

  auth: Auth;

  users: Users;

  workspaces: Workspaces;

  websites: Websites;

  contentWebsites: ContentWebsites;

  assistants: Assistants;

  conversations: Conversations;

  conversationParticipants: ConversationParticipants;

  conversationIntents: ConversationIntents;

  messages: ConversationMessages;

  agents: Agents;

  billing: Billing;

  helpCenters: HelpCenters;

  uploads: Uploads;

  integrations: Integrations;

  userSegments: UserSegments;

  featureRequests: FeatureRequests;

  messengerWidgetConfigurations: MessengerWidgetConfigurations;

  reports: Reports;

  websiteUsers: WebsiteUsers;

  macros: Macros;

  notes: Notes;

  token?: string;

  io: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;

  constructor(readonly baseURL: string) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      // eslint-disable-next-line prefer-destructuring
      const token = this.token;

      if (token) {
        const headers = new AxiosHeaders(config.headers);
        headers.set('Authorization', `Bearer ${token}`);

        const workspace = getActiveWorkspace();

        if (workspace) {
          headers.set('x-workspace-id', workspace._id);
        }

        return {
          ...config,
          headers,
        };
      }

      return config;
    });

    this.auth = new Auth(this.axios);
    this.users = new Users(this.axios);
    this.workspaces = new Workspaces(this.axios);
    this.websites = new Websites(this.axios);
    this.assistants = new Assistants(this.axios);
    this.conversations = new Conversations(this.axios);
    this.conversationParticipants = new ConversationParticipants(this.axios);
    this.conversationIntents = new ConversationIntents(this.axios);
    this.messages = new ConversationMessages(this.axios);
    this.agents = new Agents(this.axios);
    this.contentWebsites = new ContentWebsites(this.axios);
    this.billing = new Billing(this.axios);
    this.helpCenters = new HelpCenters(this.axios);
    this.uploads = new Uploads(this.axios);
    this.integrations = new Integrations(this.axios);
    this.userSegments = new UserSegments(this.axios);
    this.featureRequests = new FeatureRequests(this.axios);
    this.reports = new Reports(this.axios);
    this.websiteUsers = new WebsiteUsers(this.axios);
    this.macros = new Macros(this.axios);
    this.notes = new Notes(this.axios);
    this.messengerWidgetConfigurations = new MessengerWidgetConfigurations(this.axios);
  }

  setToken(token: string) {
    this.token = token;
  }

  socket(): Socket {
    if (!this.io) {
      this.io = io(this.baseURL, {
        auth: {
          authorization: this.token || '',
          'x-workspace-id': getActiveWorkspace()?._id || '',
        },
      });
    }

    return this.io;
  }
}

const api: Api = new Api(REACT_APP_API_URL);
export default api;

export {
  AssistantType, WorkspaceUserRole,
  ContentWebsitePageCrawlingStatus, HelpCenterArticleStatus,
  UploadType, HelpCenterVerificationStatus,
};

export * from './models/UserSegment';
export * from './models/Workspace';
export * from './models/FeatureRequest';

export type {
  Workspace, Paginated, Website, Assistant, AssistantData, ProactiveEngagementSequence,
  ConversationMessage, FAQ, WorkspaceUser, WorkspaceUserData, ConversationMetadata,
  WebsiteUser, WebsiteModule, ContentWebsitePage, HelpCenter,
  HelpCenterPatchData, HelpCenterArticle, HelpCenterArticleData, HelpCenterArticleContent,
  LocalizedValue, Upload, UserSegment, SlackChannel, PublicUpload, FeatureRequest, SearchResult,
};
