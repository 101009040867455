import { useEffect } from 'react';
import Macro from '@zupport/types/models/Macro';
import { message } from 'antd';
import { MacrosStore } from '../state';
import api from '../../api';
import useRequest from '../../common/hooks/useRequest';

export default function useMacros(enabled = true) {
  const initialized = MacrosStore.useState((s) => s.initialized);
  const items = MacrosStore.useState((s) => s.macros);

  useEffect(() => {
    if (!initialized && enabled) {
      api.macros.all().then((res) => {
        MacrosStore.update((s) => ({
          ...s,
          macros: res.items,
          initialized: true,
        }));
      });
    }
  }, [initialized, enabled]);

  const add = useRequest(async (data: Pick<Macro, 'content'>) => api.macros.create(data), {
    onSuccess: async (macro: Macro) => {
      MacrosStore.update((s) => ({
        ...s,
        macros: [macro, ...s.macros],
      }));
    },
    onError: async () => {
      message.error('Failed to create macro');
    },
  });

  const edit = useRequest(async (id: Macro['_id'], data: Pick<Macro, 'content'>) => api.macros.patch(id, data), {
    onSuccess: async (macro: Macro) => {
      MacrosStore.update((s) => ({
        ...s,
        macros: s.macros.map((m) => (m._id === macro._id ? macro : m)),
      }));
    },
    onError: async () => {
      message.error('Failed to update macro');
    },
  });

  const remove = useRequest(async (id: Macro['_id']) => api.macros.delete(id), {
    onSuccess: async (macro: Macro) => {
      MacrosStore.update((s) => ({
        ...s,
        macros: s.macros.filter((m) => m._id !== macro._id),
      }));
    },
    onError: async () => {
      message.error('Failed to delete macro');
    },
  });

  return {
    items,
    add,
    edit,
    remove,
  };
}
