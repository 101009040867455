import {
  Card, Slider, Statistic, Tag,
} from 'antd';
import { useState } from 'react';
import { SliderRangeProps } from 'antd/es/slider';
import api, { Workspace } from '../../../api';
import { BillingCreditType } from '../../../api/models/BillingCredit';
import Button from '../../../common/components/Button';
import useRequest from '../../../common/hooks/useRequest';
import plans, { free } from '../../utils/plans';
import PricingPlan from '../../types/PricingPlan';

interface UsageInfoProps {
  workspace: Workspace;
}

const CREDITS_PER_ADD_ON = 1_000;
const MIN_ADD_ONS = 1;

const INITIAL_AMOUNT = 100;

export default function UsageInfo(props: UsageInfoProps) {
  const { workspace } = props;
  let plan: PricingPlan = free;

  if (workspace.subscription?.isActive && workspace.subscription.renewOnExpiry) {
    plan = plans.find((p) => p.id === workspace.subscription?.planId) || plan;
  }

  const PRICE_PER_ADD_ON = plan.aiCreditsPrice;
  const MAX_ADD_ONS = (1000 / PRICE_PER_ADD_ON);

  const [refillQuantity, setRefillQuantity] = useState(INITIAL_AMOUNT / PRICE_PER_ADD_ON);
  const refillQuantityDisplay = (refillQuantity * CREDITS_PER_ADD_ON).toLocaleString('en-US', { maximumFractionDigits: 0 });
  const amountDisplay = (refillQuantity * PRICE_PER_ADD_ON).toLocaleString('en-US', { maximumFractionDigits: 2 });

  const request = useRequest(
    async () => api.billing.addOns.checkout.sessions.create({
      planId: plan.aiCreditsProductId,
      workspaceId: workspace._id,
      quantity: refillQuantity,
    }),
    {
      onSuccess: (result) => {
        window.location.href = result.url;
      },
    },
  );

  const marks: SliderRangeProps['marks'] = {
    [CREDITS_PER_ADD_ON]: `${(CREDITS_PER_ADD_ON / 1000).toFixed(0)}K`,
  };

  let i = 10;

  while (i <= MAX_ADD_ONS) {
    const value = i * CREDITS_PER_ADD_ON;
    marks[value] = `${(value / 1000).toFixed()}K`;

    i += 10;
  }

  return (
    <Card size="small">
      <h2 className="mt-0">Usage</h2>
      <div className="d-flex justify-content-between">
        <Statistic
          title="AI Replies Remaining"
          value={workspace.creditsLeft?.[BillingCreditType.AI_REPLY] || 0}
        />
      </div>

      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h2>Refill</h2>
          <div className="d-flex align-items-center gap-2">
            <h2 className="m-0">
              {`$${PRICE_PER_ADD_ON} per 1K credits`}
            </h2>
            {
              plan.id === free.id && (
                <Tag color="gold">
                  <b>Get 50% off with a paid plan</b>
                </Tag>
              )
            }
          </div>
        </div>
        <Slider
          min={CREDITS_PER_ADD_ON * MIN_ADD_ONS}
          max={CREDITS_PER_ADD_ON * MAX_ADD_ONS}
          step={CREDITS_PER_ADD_ON}
          value={refillQuantity * CREDITS_PER_ADD_ON}
          onChange={(newValue) => {
            setRefillQuantity(newValue / CREDITS_PER_ADD_ON);
          }}
          tooltip={{
            formatter: (value) => `${Number(value).toLocaleString('en-US')} credits`,
          }}
          marks={marks}
        />
        <div>
          <div className="d-flex align-items-center gap-4">
            <h3 className="m-0">
              {`${refillQuantityDisplay} reply credits - $${amountDisplay}`}
            </h3>
            <Button
              size="small"
              type="primary"
              color="blue"
              loading={request.loading}
              onClick={request.submit}
            >
              Refill
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
