export default function EmailIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.799999L12.8 4.11667C12.9889 4.22778 13.1333 4.37222 13.2333 4.55C13.3444 4.72778 13.4 4.92778 13.4 5.15V12.4C13.4 12.7333 13.2833 13.0167 13.05 13.25C12.8167 13.4833 12.5333 13.6 12.2 13.6H1.8C1.46667 13.6 1.18333 13.4833 0.95 13.25C0.716667 13.0167 0.6 12.7333 0.6 12.4V5.15C0.6 4.92778 0.65 4.72778 0.75 4.55C0.861111 4.37222 1.01111 4.22778 1.2 4.11667L7 0.799999ZM7 8.18333L12.2 5.2L7 2.21667L1.8 5.2L7 8.18333ZM7 9.6L1.8 6.63333V12.4H12.2V6.63333L7 9.6ZM7 12.4H12.2H1.8H7Z"
        fill="currentColor"
      />
    </svg>
  );
}
