import { NoteType } from '@zupport/types/models/Note';
import useQuery from '../../../common/hooks/useQuery';
import api from '../../../api';
import NoteView from '../../../notes/components/NoteView';
import NoteFormCard from '../../../notes/components/NoteFormCard';
import Button from '../../../common/components/Button';

interface ConversationInfoNotesProps {
  showNoteForm: boolean;
  setShowNoteForm: (show: boolean) => void;
  conversationId: string;
}

export default function ConversationInfoNotes(props: ConversationInfoNotesProps) {
  const { conversationId, showNoteForm, setShowNoteForm } = props;
  const notes = useQuery(
    async () => {
      const { items } = await api.notes.list({
        entityId: conversationId,
        $limit: 100,
      });

      return items;
    },
    [conversationId],
  );

  return (
    <div className="d-flex flex-column gap-1">
      {
        showNoteForm && (
          <NoteFormCard
            leftActions={(
              <Button
                size="small"
                onClick={() => {
                  setShowNoteForm(false);
                }}
              >
                Cancel
              </Button>
            )}
            entityId={conversationId}
            entityType={NoteType.CONVERSATION}
            onSuccess={(note) => {
              notes.setData((prev) => [note, ...(prev || [])]);
              setShowNoteForm(false);
            }}
          />
        )
      }
      {
        !notes.loading && notes.data && notes.data.length === 0 && (
          <p className="text-center m-0 text-secondary">No notes found</p>
        )
      }
      {
        notes.data && notes.data.map((note) => (
          <NoteView
            key={note._id}
            note={note}
            onEdit={(result) => {
              notes.setData((prev) => (prev || []).map((n) => (n._id === result._id ? result : n)));
            }}
            onDelete={() => {
              notes.setData((prev) => (prev || []).filter((n) => n._id !== note._id));
            }}
          />
        ))
      }
    </div>
  );
}
