import {
  Col, Form, Input, notification, Row,
} from 'antd';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import useUser from '../../auth/hooks/useUser';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';
import useAuth from '../../auth/hooks/useAuth';

export default function UserSecuritySettingsPage() {
  const user = useUser();
  const auth = useAuth();
  const [form] = Form.useForm();

  const request = useRequest(
    async (data) => api.users.patchMe(data),
    {
      onSuccess: (result) => {
        if (result.newEmail && result.newEmail !== user?.email) {
          notification.success({
            message: 'Email Verification Required',
            description: 'Please check your email to verify your new email address',
          });
        } else {
          notification.success({
            message: 'Password Updated',
          });
        }
        auth.setUser(result);
      },
      form,
    },
  );

  return (
    <Form
      className="flex-1"
      layout="vertical"
      requiredMark={false}
      form={form}
      initialValues={{
        password: '',
        newPassword: '',
      }}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="Security"
        width="medium"
        content={(
          <Row gutter={[16, 16]}>
            <Col span={24} md={14}>
              <Row gutter={[16, 16]}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="password"
                    label="Current Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your current password',
                      },
                    ]}
                  >
                    <Input.Password placeholder="Current Passowrd" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your new password',
                      },
                    ]}
                  >
                    <Input.Password placeholder="New Password" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        footer={(
          <div className="d-flex align-items-center justify-content-end flex-1">
            <Form.Item noStyle>
              <Button
                type="primary"
                loading={request.loading}
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        )}
      />
    </Form>
  );
}
