import ConversationIntent, { ConversationIntentData } from '@zupport/types/models/ConversationIntent';
import BaseService from './BaseService';

type Data = Omit<ConversationIntentData, 'module' | 'submodule'>;

export default class ConversationIntents extends BaseService {
  async create(data: Data): Promise<ConversationIntent> {
    return this._post<Data, ConversationIntent>('/conversation-intents', data);
  }

  async patch(id: ConversationIntent['_id'], data: Partial<Data>): Promise<ConversationIntent> {
    return this._patch<Partial<ConversationIntentData>, ConversationIntent>(`/conversation-intents/${id}`, data);
  }

  async remove(id: ConversationIntent['_id']): Promise<ConversationIntent> {
    return this._delete(`/conversation-intents/${id}`);
  }
}
