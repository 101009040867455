import {
  useCallback, useEffect, useState, UIEvent, useRef, SetStateAction, Dispatch,
} from 'react';
import 'simplebar-react/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import { flushSync } from 'react-dom';
import { LeftOutlined } from '@ant-design/icons';
import Conversation, {
  ConversationEngagementType,
  ConversationHandlerType,
  ConversationStatus,
  ConversationWithMetadata,
} from '@zupport/types/models/Conversation';
import ConversationParticipant from '@zupport/types/models/ConversationParticipant';
import api from '../../../api';
import useActiveWebsite from '../../../website/hooks/useActiveWebsite';
import styles from './index.module.scss';
import ConversationPreviewCard from '../ConversationPreviewCard';
import debounce from '../../../common/utils/debounce';
import useURLBuilder from '../../../common/hooks/useURLBuilder';
import { InboxPageParams } from '../../config/params';
import Select from '../../../common/components/Select';
import ConversationPreviewCardSkeleton from '../ConversationPreviewCard/ConversationPreviewCardSkeleton';
import Button from '../../../common/components/Button';
import { GLOBAL_WEBSITE_USER_MODAL_ID_PARAM } from '../../../website-users/constants';

const $limit = 20;

interface ConversationsListProps {
  conversations: ConversationWithMetadata[];
  setConversations: Dispatch<SetStateAction<ConversationWithMetadata[]>>;
  onGoBackClick?: () => void;
  agentId?: Conversation['assigneeId'];
  tab?: string;
  paramsEngagementMode?: string;
  setParamsEngagementMode?: (value: string) => void;
  paramsStatus?: string;
  setParamsStatus?: (value: string) => void;
  conversationParticipantId?: ConversationParticipant['_id'];
}

export default function ConversationsList(props: ConversationsListProps) {
  const {
    conversations,
    setConversations,
    onGoBackClick,
    agentId: propsAgentId,
    tab,
    paramsEngagementMode,
    setParamsEngagementMode,
    paramsStatus,
    setParamsStatus,
    conversationParticipantId,
  } = props;

  const website = useActiveWebsite();
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(true);
  const hasMoreRef = useRef(true);
  const urlBuilder = useURLBuilder();

  const [pagination, setPagination] = useState({
    page: 0,
    websiteId: website?._id,
    filter: tab,
    agentId: propsAgentId,
    engagementMode: paramsEngagementMode,
    status: paramsStatus,
    conversationParticipantId,
  });

  useEffect(() => {
    if (
      website
      && (tab !== 'inbox' || propsAgentId) // If filter is home, agent must be defined
    ) {
      hasMoreRef.current = true;
      flushSync(() => {
        setConversations([]);
      });
      setPagination((prev) => ({
        ...prev,
        page: 1,
        websiteId: website._id,
        filter: tab,
        agentId: propsAgentId,
        engagementMode: paramsEngagementMode,
        status: paramsStatus,
      }));
    }
  }, [
    website?._id,
    tab,
    propsAgentId,
    paramsEngagementMode,
    paramsStatus,
    conversationParticipantId,
  ]);

  useEffect(() => {
    const { websiteId, agentId } = pagination;
    if (
      pagination.page > 0
      && websiteId
      && (tab !== 'inbox' || agentId) // If filter is home, agent must be defined
    ) {
      setLoading(true);
      loadingRef.current = true;
      let assigneeId;

      if (tab === 'inbox' && agentId) {
        assigneeId = agentId;
      }

      let engagementType: ConversationEngagementType | undefined;
      let status: ConversationStatus | undefined;

      if (pagination.engagementMode === 'incoming') {
        engagementType = ConversationEngagementType.REACTIVE;
      } else if (pagination.engagementMode === 'proactive') {
        engagementType = ConversationEngagementType.PROACTIVE;
      }

      if (pagination.status === 'open') {
        status = ConversationStatus.OPEN;
      } else if (pagination.status === 'closed') {
        status = ConversationStatus.CLOSED;
      }

      api.conversations.list({
        websiteId,
        assigneeId,
        handlerType: tab === 'ai' ? ConversationHandlerType.AI : undefined,
        unassigned: tab === 'unassigned' ? true : undefined,
        $limit,
        $skip: (pagination.page - 1) * $limit,
        engagementType,
        status,
        conversationParticipantId,
      }).then((response) => {
        hasMoreRef.current = response.items.length >= $limit;
        setConversations((prev) => [
          ...(pagination.page === 1 ? [] : prev),
          ...response.items,
        ]);
      }).finally(() => {
        setLoading(false);
        loadingRef.current = false;
      });
    }
  }, [pagination]);

  // eslint-disable-next-line react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars
  const onScroll = useCallback(debounce((e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const distanceToBottom = target.scrollHeight - target.scrollTop - target.clientHeight;

    if (distanceToBottom < 500 && !loadingRef.current && hasMoreRef.current) {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  }, 500), []);

  return (
    <div className="h-100">
      <div className="p-1 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <Button
            className="mobile-only"
            onClick={onGoBackClick}
            size="small"
            type="text"
            shape="circle"
          >
            <LeftOutlined />
          </Button>
          <Select
            options={[
              {
                value: 'all',
                label: 'All',
              },
              {
                value: 'incoming',
                label: 'Incoming',
              },
              {
                value: 'proactive',
                label: 'Proactive',
              },
            ]}
            value={pagination.engagementMode}
            onChange={setParamsEngagementMode}
            style={{
              maxHeight: 32,
            }}
            variant="borderless"
            size="small"
            popupMatchSelectWidth={false}
          />
        </div>
        <Select
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'open',
              label: 'Open',
            },
            {
              value: 'closed',
              label: 'Closed',
            },
          ]}
          value={pagination.status}
          onChange={setParamsStatus}
          style={{
            maxHeight: 32,
          }}
          variant="borderless"
          size="small"
          popupMatchSelectWidth={false}
        />
      </div>
      <SimpleBar
        className={styles['conversation-list']}
        scrollableNodeProps={{ onScroll }}
      >
        {
          conversations.map((conversation, index) => (
            <ConversationPreviewCard
              key={conversation._id}
              conversation={conversation}
              index={index}
              url={urlBuilder({
                [InboxPageParams.CONVERSATION]: conversation._id,
                [GLOBAL_WEBSITE_USER_MODAL_ID_PARAM]: '',
              })}
            />
          ))
        }
        {
          loading && (
            Array.from({ length: 20 }).map(
              (_, index) => <ConversationPreviewCardSkeleton key={index?.toString()} />,
            )
          )
        }
        {
          !loading && conversations.length === 0 && (
            <p className="text-center">No conversations found</p>
          )
        }
      </SimpleBar>
    </div>
  );
}
