import { Card, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import Note from '@zupport/types/models/Note';
import { useState } from 'react';
import UserAvatar from '../../../common/components/UserAvatar';
import { getDisplayTime } from '../../../inbox/components/ConversationPreviewCard';
import Button from '../../../common/components/Button';
import Dropdown from '../../../common/components/Dropdown';
import NoteFormCard from '../NoteFormCard';
import useRequest from '../../../common/hooks/useRequest';
import api from '../../../api';

interface NoteViewProps {
  note: Pick<Note, '_id' | 'content' | 'author' | 'createdAt' | 'entityType' | 'entityId'>;
  onEdit?: (note: Note) => void;
  onDelete?: () => void;
}

export default function NoteView(props: NoteViewProps) {
  const { note, onEdit, onDelete } = props;
  const { content, author, createdAt } = note;
  const [editMode, setEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState('never');

  const remove = useRequest(
    async () => api.notes.delete(note._id),
    {
      onSuccess: onDelete,
    },
  );

  if (editMode) {
    return (
      <NoteFormCard
        note={note}
        entityId={note.entityId}
        entityType={note.entityType}
        leftActions={(
          <Button
            size="small"
            onClick={() => { setEditMode(false); }}
          >
            Cancel
          </Button>
        )}
        onSuccess={(result) => {
          if (onEdit) {
            onEdit(result);
          }
          setEditMode(false);
        }}
      />
    );
  }

  return (
    <Card
      size="small"
      bordered
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <UserAvatar
            avatar={author.avatar}
            size="small"
          />
          <span className="text-sm fw-bold">
            {[author.firstName, author.lastName].join(' ')}
          </span>
        </div>
        <div className="d-flex gap-1 align-items-center">
          <div className="text-secondary text-sm">
            {getDisplayTime(new Date(createdAt))}
          </div>
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
                onClick: () => { setEditMode(true); },
              },
              {
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined />,
                onClick: () => { setDeleteModal('open'); },
              },
            ]}
            overlayStyle={{ minWidth: 150 }}
          >
            <Button
              size="small"
              icon={<MoreOutlined />}
              type="text"
              style={{
                maxHeight: 20,
                maxWidth: 20,
              }}
            />
          </Dropdown>
        </div>
      </div>
      <div className="mt-1">
        {content}
      </div>
      {
        deleteModal !== 'never' && (
          <Modal
            title="Delete Note"
            onCancel={() => { setDeleteModal('closed'); }}
            open={deleteModal === 'open'}
            okText="Delete Note"
            onOk={remove.submit}
            okButtonProps={{
              loading: remove.loading,
              disabled: remove.loading,
            }}
          >
            Are you sure you want to delete this note?
          </Modal>
        )
      }
    </Card>
  );
}
