import { useCallback } from 'react';
import { notification } from 'antd';
import User from '@zupport/types/models/User';
import api, { getActiveWorkspace, Workspace } from '../../api';
import {
  clearToken, clearUser, getToken,
  setToken, setUser,
} from '../utils';
import { AuthStore } from '../state';
import {
  clearActiveWorkspace, setActiveWorkspace, WorkspaceStore,
} from '../../workspace/state';
import InboxStore from '../../inbox/state';

type SignUpData = Pick<User, 'firstName' | 'lastName' | 'email' | 'password'> & {
  params?: Record<string, string>;
  inviteToken?: string;
};

type UseAuth = {
  authenticated: boolean;
  signUp: (data: SignUpData) => Promise<User>;
  signIn: (email: string, password: string) => Promise<void>;
  logout: () => void;
  authenticate: () => Promise<void>;
  setUser: (user: User) => void;
};

export default function useAuth(): UseAuth {
  const authenticated = AuthStore.useState((s) => s.authenticated);

  const signIn = useCallback(async (email: string, password: string) => {
    const result = await api.auth.signIn(email, password);
    setUser(result.user);
    setToken(result.token);
    api.setToken(result.token);

    const workspaces = await api.workspaces.my();
    const [workspace] = workspaces.items;

    if (workspace) {
      await setActiveWorkspace(workspace);
    }

    AuthStore.update((s) => {
      // eslint-disable-next-line no-param-reassign
      s.authenticated = true;
      // eslint-disable-next-line no-param-reassign
      s.user = result.user;
      // eslint-disable-next-line no-param-reassign
      s.token = result.token;
    });
  }, []);

  const signUp = useCallback(async (data: Pick<User, 'firstName' | 'lastName' | 'email' | 'password'>) => {
    const result = await api.auth.signUp(data);
    await signIn(data.email, data.password);
    return result;
  }, [signIn]);

  const logout = useCallback(() => {
    clearToken();
    clearUser();
    AuthStore.update((s) => {
      // eslint-disable-next-line no-param-reassign
      s.authenticated = false;
      // eslint-disable-next-line no-param-reassign
      s.user = undefined;

      window.location.href = '/';
    });
  }, []);

  const authenticate = useCallback(async () => {
    const token = getToken();
    if (token) {
      api.setToken(token);

      try {
        const newUser = await api.users.me();
        setUser(newUser);
        AuthStore.update((s) => {
          // eslint-disable-next-line no-param-reassign
          s.authenticated = true;
          // eslint-disable-next-line no-param-reassign
          s.user = newUser;
          // eslint-disable-next-line no-param-reassign
          s.token = token;
        });

        let workspace: Workspace | undefined = getActiveWorkspace();

        if (workspace && !newUser.workspaceIds.includes(workspace._id)) {
          clearActiveWorkspace();
          workspace = undefined;
        }

        const activeWorkspaceId = workspace?._id
          || newUser.workspaceIds[0];

        if (activeWorkspaceId) {
          workspace = await api.workspaces.get(activeWorkspaceId);
          await setActiveWorkspace(workspace);
        } else if (newUser.verified) {
          notification.error({
            message: 'No workspace found',
            description: 'Please contact support',
          });
        }

        if (workspace) {
          const [participant, agents, workspaces] = await Promise.all([
            api.conversationParticipants.me(),
            api.agents.list({
              websiteId: workspace?.websiteId,
            }),
            api.workspaces.my(),
          ]);
          const myAgent = agents.items.find((agent) => participant.entityId === agent._id) || null;

          WorkspaceStore.update((s) => ({
            ...s,
            workspaces: workspaces.items,
          }));

          InboxStore.update((s) => ({
            ...s,
            participant,
            agents: agents.items,
            agent: myAgent,
          }));
        }
      } catch (e) {
        clearToken();
        clearUser();
        AuthStore.update((s) => {
          // eslint-disable-next-line no-param-reassign
          s.authenticated = false;
          // eslint-disable-next-line no-param-reassign
          s.user = undefined;
          // eslint-disable-next-line no-param-reassign
          s.token = undefined;
        });
      }
    } else {
      AuthStore.update((s) => {
        // eslint-disable-next-line no-param-reassign
        s.authenticated = false;
        // eslint-disable-next-line no-param-reassign
        s.user = undefined;
        // eslint-disable-next-line no-param-reassign
        s.token = undefined;
      });
    }
  }, []);

  const setUserCallback = useCallback((user: User) => {
    setUser(user);
    AuthStore.update((s) => {
      // eslint-disable-next-line no-param-reassign
      s.user = user;
    });
  }, []);

  return {
    authenticated,
    signUp,
    signIn,
    logout,
    authenticate,
    setUser: setUserCallback,
  };
}
