import { get } from './objects.js';
export function interpolate(text, values) {
    var result = text;
    // Extract a list of variables (they're between {{ and }}) from the text
    var variables = text.match(/{{(.*?)}}/g);
    if (!variables) {
        return result;
    }
    // Replace each variable with its value
    variables.forEach(function (variable) {
        var key = variable.slice(2, -2);
        var value = get(key, values);
        if (typeof value === 'string' || typeof value === 'number') {
            result = result.replaceAll(variable, String(value));
        }
    });
    return result;
}
