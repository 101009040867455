/* eslint-disable react/no-unstable-nested-components */

import {
  Divider, Dropdown as AntDropdown, DropdownProps as AntDropdownProps,
} from 'antd';
import React, { ReactNode } from 'react';

export type DropdownItem = {
  key: string;
  label: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
};

interface DropdownProps extends Omit<AntDropdownProps, 'menu'> {
  items: DropdownItem[];
  children: ReactNode;
  header?: ReactNode;
}

export default function Dropdown(props: DropdownProps) {
  const {
    items,
    header,
    children,
    ...rest
  } = props;

  return (
    <AntDropdown
      menu={{ items }}
      dropdownRender={(menu) => (
        <div className="app-dropdown-content">
          {
            header && (
              <>
                <div className="ant-dropdown-header">
                  {header}
                </div>
                <Divider style={{ margin: 0 }} />
              </>
            )
          }
          {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none' } })}
        </div>
      )}
      arrow={false}
      {...rest}
    >
      {children}
    </AntDropdown>
  );
}
