"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AIToolResultStatus = exports.AIToolEnvironment = exports.AIToolExecutionMode = exports.AIToolMode = void 0;
var AIToolMode;
(function (AIToolMode) {
    AIToolMode["READ"] = "read";
    AIToolMode["WRITE"] = "write";
})(AIToolMode || (exports.AIToolMode = AIToolMode = {}));
var AIToolExecutionMode;
(function (AIToolExecutionMode) {
    AIToolExecutionMode["AUTO"] = "auto";
    AIToolExecutionMode["MANUAL"] = "manual";
})(AIToolExecutionMode || (exports.AIToolExecutionMode = AIToolExecutionMode = {}));
var AIToolEnvironment;
(function (AIToolEnvironment) {
    AIToolEnvironment["CLIENT"] = "client";
    AIToolEnvironment["SERVER"] = "server";
})(AIToolEnvironment || (exports.AIToolEnvironment = AIToolEnvironment = {}));
var AIToolResultStatus;
(function (AIToolResultStatus) {
    AIToolResultStatus["SUCCESS"] = "success";
    AIToolResultStatus["ERROR"] = "error";
})(AIToolResultStatus || (exports.AIToolResultStatus = AIToolResultStatus = {}));
