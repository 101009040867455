import { useSearchParams } from 'react-router-dom';
import {
  BaseSyntheticEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { notification } from 'antd';
import { InfoOutlined, LeftOutlined } from '@ant-design/icons';
import ConversationParticipant, {
  ConversationParticipantType,
} from '@zupport/types/models/ConversationParticipant';
import Conversation, { ConversationStatus } from '@zupport/types/models/Conversation';
import ConversationIntent from '@zupport/types/models/ConversationIntent';
import Agent, { AgentEntityType } from '@zupport/types/models/Agent';
import api, { ConversationMessage } from '../../../api';
import styles from './index.module.scss';
import UserAvatar from '../../../common/components/UserAvatar';
import Button from '../../../common/components/Button';
import useParticipant from '../../hooks/useParticipant';
import throttle from '../../../common/utils/throttle';
import { InboxPageParams } from '../../config/params';
import ConversationInfo from '../ConversationInfo';
import CloseConversationDialog from '../CloseConversationDialog';
import useRequest from '../../../common/hooks/useRequest';
import ConversationMessageView from '../ConversationMessageView';
import ConversationViewInput from '../ConversationViewInput';
import useAgent from '../../hooks/useAgent';
import useConversationPermissions from '../../hooks/useConversationPermissions';

function getParticipants(participants: ConversationParticipant[], agent?: Agent | null) {
  if (!agent) {
    return participants;
  }

  return participants.filter(
    (participant) => participant.type !== ConversationParticipantType.AGENT
      || participant.entityId !== agent._id,
  );
}

function isSameMessage(a: ConversationMessage, b: ConversationMessage) {
  if (a._id === b._id) {
    return true;
  }

  return a.clientId && b.clientId && a.clientId === b.clientId;
}

const LIMIT = 40;

interface ConversationViewProps {
  onGoBackClick?: () => void;
  onGoForwardClick?: () => void;
}

export default function ConversationView(props: ConversationViewProps) {
  const { onGoBackClick, onGoForwardClick } = props;

  const [params] = useSearchParams();
  const id = params.get(InboxPageParams.CONVERSATION);
  const [conversation, setConversation] = useState<Conversation>();
  const [participants, setParticipants] = useState<ConversationParticipant[]>();
  const [messages, setMessages] = useState<ConversationMessage[]>([]);
  const [messageContent, setMessageContent] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const blockAutoScrollRef = useRef(false);
  const participant = useParticipant();
  const containerRef = useRef<HTMLDivElement>(null);
  const agent = useAgent();
  const conversationPermissions = useConversationPermissions(conversation);

  const messageValid = messageContent.trim().length > 0;

  const onScroll = useCallback(throttle((e: BaseSyntheticEvent<HTMLDivElement>) => {
    if (!loading && id && hasMore) {
      const distanceToTop = e.target.scrollTop;

      if (distanceToTop <= 500) {
        // Load more
        const [last] = messages;
        api.messages.list({
          $trail: last ? last._id : undefined,
          conversationId: id,
          $limit: LIMIT,
        }).then((response) => {
          setHasMore(response.items.length >= 20);
          blockAutoScrollRef.current = true;
          setMessages((prev) => [...response.items.reverse(), ...prev]);
          setTimeout(() => {
            blockAutoScrollRef.current = false;
          }, 100);
        });
      }
    }
  }, 500), [loading, messages, id, hasMore]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      api.conversations.get(id).then(setConversation);
      api.messages.list({
        conversationId: id,
        $limit: LIMIT,
      }).then((response) => {
        setMessages(response.items.reverse());
        setHasMore(response.items.length >= 20);
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      });

      const onMessageCreated = (message: ConversationMessage) => {
        if (message.conversationId === id) {
          setMessages((prev) => {
            const exists = prev.find((item) => isSameMessage(item, message));

            if (!exists) {
              return [...prev, message];
            }

            return prev.map((item) => (isSameMessage(item, message) ? message : item));
          });
        }
      };

      const onConversationUpdated = (updatedConversation: Conversation) => {
        if (updatedConversation._id === id) {
          setConversation(updatedConversation);
        }
      };

      api.socket().on('conversation:message:created', onMessageCreated);
      api.socket().on('conversation:updated', onConversationUpdated);

      return () => {
        api.socket().off('conversation:message:created', onMessageCreated);
        api.socket().off('conversation:updated', onConversationUpdated);
      };
    }
    setLoading(false);

    return () => {};
  }, [id]);

  useEffect(() => {
    if (conversation) {
      setParticipants(getParticipants(Object.values(conversation.participants), agent));
    }
  }, [conversation, agent?._id]);

  useEffect(() => {
    if (!blockAutoScrollRef.current) {
      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [messages]);

  useEffect(() => {
    if (participant?._id
      && conversation?.participants?.[participant._id]
    ) {
      api.conversations.markAsRead(conversation?._id).then();
    }
  }, [conversation?._id, participant?._id]);

  const onSendMessage = useCallback(() => {
    if (!conversation) {
      notification.error({
        message: 'Conversation not found',
      });
    } else if (!participant) {
      notification.error({
        message: 'Participant not found',
      });
    } else if (messageValid) {
      const clientId = Date.now().toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const message: ConversationMessage = {
        _id: clientId,
        clientId,
        message: messageContent,
        conversationId: conversation._id,
        createdAt: new Date(),
        updatedAt: new Date(),
        participant,
        workspaceId: conversation.workspaceId,
      };

      setMessages((prev) => [...prev, message]);
      setMessageContent('');

      api.messages.create({
        message: messageContent,
        conversationId: conversation._id,
        clientId,
      }).then((result) => {
        // Replace the temporary message with the actual message
        setMessages(
          (prev) => prev.map((item) => (item.clientId === result.clientId ? result : item)),
        );
      });
    }
  }, [messageContent, messageValid, conversation]);

  const onIntentCreated = useCallback((intent: ConversationIntent) => {
    setConversation((prev) => {
      if (prev) {
        return {
          ...prev,
          intents: prev.intents ? [intent, ...prev.intents] : [intent],
        };
      }

      return prev;
    });
  }, []);

  const onIntentUpdated = useCallback((intent: ConversationIntent) => {
    setConversation((prev) => {
      if (prev) {
        return {
          ...prev,
          intents: prev.intents
            ? prev.intents.map((item) => (item._id === intent._id ? intent : item))
            : [intent],
        };
      }

      return prev;
    });
  }, []);

  const onIntentRemoved = useCallback((intent: ConversationIntent) => {
    setConversation((prev) => {
      if (prev) {
        return {
          ...prev,
          intents: prev.intents
            ? prev.intents.filter((item) => item._id !== intent._id)
            : [],
        };
      }

      return prev;
    });
  }, []);

  const [closeDialogOpen, setCloseDialogOpen] = useState(false);

  const closeConversationRequest = useRequest(async () => {
    if (!conversation) {
      throw new Error('Conversation not found');
    }

    return api.conversations.close(conversation._id);
  }, {
    onSuccess: (result) => {
      notification.success({
        message: 'Conversation closed',
      });
      setConversation(result);
      setCloseDialogOpen(false);
    },
  });

  if (!conversation) {
    return null;
  }

  const [otherParticipant] = participants || [];

  let previousMessage: ConversationMessage | undefined;

  return (
    <div className={styles.container}>
      <CloseConversationDialog
        open={closeDialogOpen}
        onCancel={() => { setCloseDialogOpen(false); }}
        conversation={conversation}
        onOk={closeConversationRequest.submit}
        loading={closeConversationRequest.loading}
      />
      <div className={styles.conversationContainer}>
        <div className={styles.header}>
          {
            otherParticipant && (
              <div className="d-flex align-items-center gap-2">
                <Button
                  className="mobile-only"
                  onClick={onGoBackClick}
                  size="small"
                  type="text"
                  shape="circle"
                >
                  <LeftOutlined />
                </Button>
                <div className={styles.user}>
                  <UserAvatar
                    className={styles.avatar}
                    avatar={otherParticipant.avatar}
                    fallback={false}
                    size={28}
                  >
                    {otherParticipant.name[0]}
                  </UserAvatar>
                  <p className={styles.name}>
                    {otherParticipant.name}
                  </p>
                </div>
              </div>
            )
          }
          <div className="d-flex align-items-center gap-2">
            {
              conversation.status !== ConversationStatus.CLOSED && (
                <Button
                  size="small"
                  onClick={() => {
                    setCloseDialogOpen(true);
                  }}
                >
                  Close
                </Button>
              )
            }
            <Button
              className="mobile-only"
              size="small"
              shape="circle"
              onClick={onGoForwardClick}
            >
              <InfoOutlined />
            </Button>
          </div>
        </div>
        <SimpleBar
          className={styles.messages}
          scrollableNodeProps={{
            ref: containerRef,
            onScroll,
          }}
        >
          {
            messages && messages.map((message) => {
              const own = message.participant._id === participant?._id
                || message.participant.agentEntityType === AgentEntityType.ASSISTANT;

              const divergent = !previousMessage
                || previousMessage.participant._id !== message.participant._id;

              previousMessage = message;

              return (
                <ConversationMessageView
                  message={message}
                  own={own}
                  divergent={divergent}
                />
              );
            })
          }
        </SimpleBar>
        <ConversationViewInput
          status={conversation.status}
          onSendMessage={onSendMessage}
          messageContent={messageContent}
          setMessageContent={setMessageContent}
          websiteUser={conversation.websiteUser}
          canReply={conversationPermissions.reply}
        />
      </div>
      <div className={styles.conversationInfo}>
        {
          conversation && (
            <ConversationInfo
              conversation={conversation}
              onUpdate={setConversation}
              onIntentCreated={onIntentCreated}
              onIntentUpdated={onIntentUpdated}
              onIntentRemoved={onIntentRemoved}
              onGoBackClick={onGoBackClick}
            />
          )
        }
      </div>
    </div>
  );
}
