import BaseService from './BaseService';
import Workspace from '../models/Workspace';

type CreateCheckoutSessionData = {
  planId: string;
  quantity: number;
  workspaceId: string;
};

export default class Billing extends BaseService {
  subscriptions = {
    checkout: {
      sessions: {
        create: (
          data:CreateCheckoutSessionData,
        ): Promise<{ url: string }> => this._post<CreateCheckoutSessionData, { url: string }>(
          '/billing/subscriptions/checkout/sessions',
          data,
        ),
      },
    },
    change: async (
      planId: string,
      workspaceId: string,
    ): Promise<Workspace> => this._post<{ planId: string; workspaceId: string; }, Workspace>(
      '/billing/subscriptions/change',
      {
        planId,
        workspaceId,
      },
    ),
  };

  addOns = {
    checkout: {
      sessions: {
        create: (
          data:CreateCheckoutSessionData,
        ): Promise<{ url: string }> => this._post<CreateCheckoutSessionData, { url: string }>(
          '/billing/add-ons/checkout/sessions',
          data,
        ),
      },
    },
  };

  portal = {
    sessions: {
      create: (
        data: { workspaceId: string },
      ): Promise<{ url: string }> => this._post<{ workspaceId: string }, { url: string }>(
        '/billing/portal/sessions',
        data,
      ),
    },
  };
}
