import { Input } from 'antd';
import { useRef, useState } from 'react';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { SendOutlined } from '@ant-design/icons';
import Conversation, { ConversationStatus } from '@zupport/types/models/Conversation';
import styles from './index.module.scss';
import Button from '../../../common/components/Button';
import useKeyDown from '../../../common/hooks/useKeyDown';
import ConversationInputMacrosPopup from '../ConversationInputMacrosPopup';
import useActiveWorkspaceUser from '../../../workspace/hooks/useActiveWorkspaceUser';

interface ConversationViewInputProps extends Pick<Conversation, 'websiteUser'> {
  status: ConversationStatus;
  onSendMessage: () => void;
  messageContent: string;
  setMessageContent: (value: string) => void;
  canReply: boolean;
}

export default function ConversationViewInput(props: ConversationViewInputProps) {
  const {
    status, onSendMessage, messageContent, setMessageContent, websiteUser, canReply,
  } = props;
  const inputRef = useRef<TextAreaRef>(null);
  const [openMacrosKey, setOpenMacrosKey] = useState<number>();
  const user = useActiveWorkspaceUser();

  const keyDown = useKeyDown(['Control', 'Meta'], 'some');

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputContainerInner}>
        {
          inputRef?.current?.resizableTextArea?.textArea && (
            <ConversationInputMacrosPopup
              input={inputRef?.current?.resizableTextArea?.textArea}
              onSelect={setMessageContent}
              openKey={openMacrosKey}
              context={{
                user: websiteUser ? {
                  name: websiteUser.name,
                  firstName: websiteUser.firstName,
                  lastName: websiteUser.lastName,
                  email: websiteUser.email,
                } : undefined,
                me: user ? {
                  name: [user.firstName, user.lastName].join(' '),
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                } : undefined,
              }}
            />
          )
        }
        <Input.TextArea
          ref={inputRef}
          className={styles.input}
          placeholder={
            status === ConversationStatus.CLOSED
              ? 'You can\'t reply to closed conversations'
              : 'Type a message...'
          }
          onChange={(e) => setMessageContent(e.target.value)}
          onPressEnter={() => {
            if (keyDown) {
              onSendMessage();
            }
          }}
          value={messageContent}
          disabled={status === ConversationStatus.CLOSED || !canReply}
        />
        <div className={styles.inputActionsContainer}>
          <div className={styles.inputActionsContainerGroup}>
            <Button
              type="primary"
              size="small"
              disabled={status === ConversationStatus.CLOSED}
              className={styles.inputActionsContainerGroupButton}
              style={{ height: 18 }}
              onClick={() => {
                setOpenMacrosKey(Date.now());
              }}
            >
              #
            </Button>
          </div>
          <div className={styles.inputActionsContainerGroup}>
            <Button
              type="primary"
              size="small"
              disabled={status === ConversationStatus.CLOSED}
              className={styles.inputActionsContainerGroupButton}
              style={{ height: 18 }}
              onClick={onSendMessage}
            >
              <SendOutlined style={{ fontSize: 12 }} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
