import { Form, Input } from 'antd';
import { ReactNode } from 'react';
import Note, { NoteData } from '@zupport/types/models/Note';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';

export interface NoteFormProps extends Pick<NoteData, 'entityId' | 'entityType'> {
  leftActions?: ReactNode | ReactNode[];
  onSuccess?: (note: Note) => void;
  note?: Pick<Note, '_id'> & Partial<Note>;
}

export default function NoteForm(props: NoteFormProps) {
  const {
    leftActions, entityId, entityType, onSuccess, note,
  } = props;

  const [form] = Form.useForm();

  const request = useRequest(
    async (data: Pick<NoteData, 'content'>) => {
      if (note) {
        return api.notes.patch(note._id, data);
      }

      return api.notes.create({
        ...data,
        entityId,
        entityType,
      });
    },
    {
      form,
      onSuccess,
    },
  );

  return (
    <Form
      onFinish={request.submit}
      initialValues={{
        content: note?.content || '',
      }}
    >
      <Form.Item name="content">
        <Input.TextArea placeholder="Write a note" />
      </Form.Item>
      <div className="mt-1">
        <div className="d-flex justify-content-between">
          <span>
            {leftActions}
          </span>
          <Form.Item className="m-0">
            <Button
              htmlType="submit"
              size="small"
              type="primary"
              disabled={request.loading}
              loading={request.loading}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}
