import {
  Card, Col, message, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import plans from '../../billing/utils/plans';
import PricingPlanCard, { PricingPlanCardProps } from '../components/PricingPlanCard';
import useActiveWorkspace from '../hooks/useActiveWorkspace';
import useRequest from '../../common/hooks/useRequest';
import api, { WorkspaceUser } from '../../api';
import SubscriptionInfo from '../../billing/components/SubscriptionInfo';
import useQuery from '../../common/hooks/useQuery';
import UsageInfo from '../../billing/components/UsageInfo';
import { setActiveWorkspace } from '../state';

export default function WorkspaceBillingPage() {
  const workspace = useActiveWorkspace();
  const location = useLocation();
  const subscribeSuccess = location.search.includes('subscribe-success=true');

  useEffect(() => {
    if (subscribeSuccess) {
      ReactPixel.track('Subscribe');
    }
  }, [subscribeSuccess]);

  const [loadingPlan, setLoadingPlan] = useState<string>();

  const { data } = useQuery<WorkspaceUser[]>(async () => {
    if (!workspace) {
      throw new Error('No active workspace');
    }

    const result = await api.workspaces.users.list(workspace._id);
    return result.items;
  }, [workspace?._id]);
  const minQuantity = data?.length || 1;

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity((prev) => (prev < minQuantity ? minQuantity : prev));
  }, [minQuantity]);

  const request = useRequest(
    async (planId: string) => {
      if (!workspace) {
        throw new Error('No active workspace found');
      }

      return api.billing.subscriptions.checkout.sessions.create({
        workspaceId: workspace._id,
        planId,
        quantity,
      });
    },
    {
      onSuccess: (result) => {
        window.location.href = result.url;
        setLoadingPlan(undefined);
      },
      onError: () => {
        setLoadingPlan(undefined);
      },
    },
  );

  const upgradeRequest = useRequest(
    async (planId: string) => {
      if (!workspace?._id) {
        throw new Error('No active workspace found');
      }

      return api.billing.subscriptions.change(
        planId,
        workspace._id,
      );
    },
    {
      onSuccess: (result) => {
        setActiveWorkspace(result);
        message.success('Subscription updated, thank you');
        setLoadingPlan(undefined);
      },
      onError: () => {
        message.error('Failed to update subscription');
        setLoadingPlan(undefined);
      },
    },
  );

  const downgradeRequest = useRequest(
    async (planId: string) => {
      if (!workspace?._id) {
        throw new Error('No active workspace found');
      }

      return api.billing.subscriptions.change(
        planId,
        workspace._id,
      );
    },
    {
      onSuccess: (result) => {
        setActiveWorkspace(result);
        message.success('Subscription updated, thank you');
        setLoadingPlan(undefined);
      },
      onError: () => {
        message.error('Failed to update subscription');
        setLoadingPlan(undefined);
      },
    },
  );

  const resumeRequest = useRequest(
    async (planId: string) => {
      if (!workspace?._id) {
        throw new Error('No active workspace found');
      }

      return api.billing.subscriptions.change(
        planId,
        workspace._id,
      );
    },
    {
      onSuccess: (result) => {
        setActiveWorkspace(result);
        message.success('Subscription resumed, thank you');
        setLoadingPlan(undefined);
      },
      onError: () => {
        message.error('Failed to resume subscription');
        setLoadingPlan(undefined);
      },
    },
  );

  const subscription = workspace?.subscription;
  const hasPausedSubscription = subscription?.isActive && !subscription.renewOnExpiry;

  return (
    <AppSubPageLayoutContent
      title="Billing"
      content={(
        <div className="pb-5">
          {
            workspace && (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <SubscriptionInfo workspace={workspace} />
                </Col>
                <Col span={24}>
                  <UsageInfo workspace={workspace} />
                </Col>
              </Row>
            )
          }
          <Card size="small" className="mt-4">
            <Row>
              <Col span={24}>
                <h2 className="mt-0">
                  Plans & Pricing
                </h2>
              </Col>
            </Row>
            <Row
              className="mt-4"
              justify="center"
              gutter={[16, 16]}
            >
              {
                plans.map((plan) => {
                  let isActive = false;
                  let action: PricingPlanCardProps['action'] = 'current';

                  const activePlan = plans.find((p) => workspace?.subscription?.planId === p.id);

                  if (
                    (!subscription && !plan.price)
                    || (!plan.price && subscription && !subscription.renewOnExpiry)
                  ) {
                    isActive = true;
                  } else if (workspace?.subscription?.planId === plan.id) {
                    isActive = true;
                  }

                  const currentPlanPrice = hasPausedSubscription ? 0 : (activePlan?.price || 0);

                  if (activePlan) {
                    if (
                      subscription?.isActive
                      && !subscription?.renewOnExpiry
                      && activePlan.id === plan.id
                    ) {
                      action = 'resume';
                    } else if (plan.price > currentPlanPrice) {
                      action = 'upgrade';
                    } else if (plan.price < currentPlanPrice) {
                      action = 'downgrade';
                    }
                  } else {
                    action = 'subscribe';
                  }

                  return (
                    <Col
                      key={`${plan.name}-${activePlan?.id}`}
                      span={24}
                      xl={8}
                      xxl={6}
                    >
                      <PricingPlanCard
                        plan={plan}
                        onSubscribe={(planId) => {
                          request.submit(planId);
                          setLoadingPlan(planId);
                        }}
                        onUpgrade={(planId) => {
                          upgradeRequest.submit(planId);
                          setLoadingPlan(planId);
                        }}
                        upgradeLoading={upgradeRequest.loading && loadingPlan === plan.id}
                        onDowngrade={(planId) => {
                          downgradeRequest.submit(planId);
                          setLoadingPlan(planId);
                        }}
                        downgradeLoading={downgradeRequest.loading && loadingPlan === plan.id}
                        onResume={(planId) => {
                          resumeRequest.submit(planId);
                          setLoadingPlan(planId);
                        }}
                        resumeLoading={resumeRequest.loading && loadingPlan === plan.id}
                        subscribeLoading={loadingPlan === plan.id}
                        subscribeDisabled={Boolean(loadingPlan)}
                        active={isActive}
                        action={action}
                      />
                    </Col>
                  );
                })
              }
            </Row>
          </Card>
        </div>
      )}
    />
  );
}
