import {
  useEffect, useState,
} from 'react';
import 'simplebar-react/dist/simplebar.min.css';
import { useSearchParams } from 'react-router-dom';
import Conversation, { ConversationWithMetadata } from '@zupport/types/models/Conversation';
import api, { ConversationMetadata } from '../../../api';
import useAgent from '../../hooks/useAgent';
import { InboxPageParams } from '../../config/params';
import useSearchParam from '../../../common/hooks/useSearchParam';
import ConversationsList from '../ConversationsList';

interface InboxPageConversationsListProps {
  onGoBackClick?: () => void;
  onConversationChange?: (id?: string | null) => void;
}

export default function InboxPageConversationsList(props: InboxPageConversationsListProps) {
  const { onConversationChange, onGoBackClick } = props;

  const [params] = useSearchParams();
  const id = params.get(InboxPageParams.CONVERSATION);
  const agent = useAgent();
  console.info('agent', agent);
  const [conversations, setConversations] = useState<ConversationWithMetadata[]>([]);

  useEffect(() => {
    if (onConversationChange) {
      onConversationChange(id);
    }
  }, [id, onConversationChange]);

  const [tab] = useSearchParam(InboxPageParams.TAB, 'inbox');
  const [paramsEngagementMode, setParamsEngagementMode] = useSearchParam(InboxPageParams.ENGAGEMENT_TYPE, 'incoming');
  const [paramsStatus, setParamsStatus] = useSearchParam(InboxPageParams.STATUS, 'open');

  useEffect(() => {
    const updateConversation = (conversation: Conversation, metadata: ConversationMetadata) => {
      setConversations((prev) => prev.map((c) => {
        if (c._id === conversation._id) {
          return {
            ...conversation,
            metadata: {
              ...metadata,
              unreadMessagesCount: conversation._id === id ? 0 : metadata.unreadMessagesCount,
            },
          };
        }

        return c;
      }));
    };

    api.socket().on('conversation:metadata:updated', updateConversation);

    return () => {
      api.socket().off('conversation:metadata:updated', updateConversation);
    };
  }, [id]);

  return (
    <ConversationsList
      conversations={conversations}
      setConversations={setConversations}
      onGoBackClick={onGoBackClick}
      agentId={agent?._id}
      tab={tab}
      paramsEngagementMode={paramsEngagementMode}
      setParamsEngagementMode={setParamsEngagementMode}
      paramsStatus={paramsStatus}
      setParamsStatus={setParamsStatus}
    />
  );
}
