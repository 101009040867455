import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import styles from './index.module.scss';
import InboxPageConversationsList from '../../components/InboxPageConversationsList';
import ConversationView from '../../components/ConversationView';
import InboxPageSidebar from '../../components/InboxPageSidebar';
import useIsMobile from '../../../common/hooks/useIsMobile';

export default function InboxPage() {
  const container = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const containerInitialized = Boolean(container.current);
  const [initialized, setInitialized] = useState(false);
  const [page, setPage] = useState(0);

  const scrollToPage = (index: number) => {
    if (container.current && isMobile) {
      container.current.scroll({
        left: window.innerWidth * index,
        behavior: 'smooth',
      });
      setPage(index);
    }
  };

  useEffect(() => {
    if (isMobile && container.current) {
      scrollToPage(1);
      setInitialized(true);
    }
  }, [containerInitialized, isMobile]);

  const onConversationChange = useCallback((id?: string | null) => {
    if (id && initialized) {
      setTimeout(() => {
        scrollToPage(2);
      }, 200);
    }
  }, [isMobile, initialized]);

  const onGoBackClick = useCallback(() => {
    if (isMobile && initialized) {
      scrollToPage(page - 1);
    }
  }, [isMobile, initialized, page]);

  const onGoForwardClick = useCallback(() => {
    if (isMobile && initialized) {
      scrollToPage(page + 1);
    }
  }, [isMobile, initialized, page]);

  return (
    <div
      ref={container}
      className={styles.container}
    >
      <div className={styles.sidebar}>
        <InboxPageSidebar onItemClick={onGoForwardClick} />
      </div>
      <div className={styles.conversations}>
        <InboxPageConversationsList
          onGoBackClick={onGoBackClick}
          onConversationChange={onConversationChange}
        />
      </div>
      <div className={styles.conversation}>
        <ConversationView
          onGoBackClick={onGoBackClick}
          onGoForwardClick={onGoForwardClick}
        />
      </div>
    </div>
  );
}
