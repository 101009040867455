import PricingPlan from '../types/PricingPlan';

const ONE_K_ADD_ON_ID = '1k-add-on';
const ONE_K_ADD_ON_ID_ON_FREE_PLAN = '1k-add-on-on-free-plan';

export const free: PricingPlan = {
  // Do not change the id, it has to be the same as in the backend
  id: 'free',
  name: 'Free',
  price: 0,
  aiRepliesCount: 0,
  proactiveEngagementEnabled: false,
  seatsCount: 5,
  helpCentersCount: 1,
  multilingualHelpCentersEnabled: false,
  // The ai credits cost $20 per 1K credits on the free plan
  aiCreditsPrice: 20,
  aiCreditsProductId: ONE_K_ADD_ON_ID_ON_FREE_PLAN,
  membersLimit: 5,
};

const plans: PricingPlan[] = [
  free,
  {
    // Do not change the id, it has to be the same as in the backend
    id: 'professional',
    name: 'Professional',
    price: 97,
    aiRepliesCount: 1000,
    proactiveEngagementEnabled: true,
    seatsCount: Infinity,
    helpCentersCount: Infinity,
    multilingualHelpCentersEnabled: true,
    aiCreditsPrice: 10,
    aiCreditsProductId: ONE_K_ADD_ON_ID,
    membersLimit: Infinity,
  },
  {
    id: 'premium',
    name: 'Premium',
    price: 997,
    aiRepliesCount: 10000,
    proactiveEngagementEnabled: true,
    seatsCount: Infinity,
    helpCentersCount: Infinity,
    multilingualHelpCentersEnabled: true,
    premiumSupport: true,
    aiIntegrationSupport: true,
    aiCreditsPrice: 10,
    aiCreditsProductId: ONE_K_ADD_ON_ID,
    membersLimit: Infinity,
  },
];

export default plans;
