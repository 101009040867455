import { Link } from 'react-router-dom';
import ConversationMessage from '@zupport/types/models/ConversationMessage';
import { MessageBlockType } from '@zupport/types/types/MessageBlock';
import { ConversationParticipantType } from '@zupport/types/models/ConversationParticipant';
import { ReactNode } from 'react';
import { ConversationWithMetadata } from '@zupport/types/models/Conversation';
import styles from './index.module.scss';
import UserAvatar from '../../../common/components/UserAvatar';
import cn from '../../../common/utils/cn';

interface ConversationPreviewCardProps {
  conversation: ConversationWithMetadata;
  index: number;
  url: string;
}

export function getDisplayTime(date: Date) {
  const isToday = new Date().toDateString() === date.toDateString();

  if (isToday) {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  const isDifferentYear = new Date().getFullYear() !== date.getFullYear();

  return date.toLocaleDateString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    month: 'short',
    day: 'numeric',
    year: isDifferentYear ? 'numeric' : undefined,
  });
}

const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

function getLastMessageText(conversationMessage?: ConversationMessage): ReactNode {
  const { message, blocks } = conversationMessage || {};

  if (message) {
    return message;
  }

  if (blocks) {
    let i = 0;

    while (i < blocks.length) {
      const block = blocks[i];
      if (block.type === MessageBlockType.TEXT) {
        return block.content;
      }

      i += 1;
    }
  }

  return 'No messages';
}

export default function ConversationPreviewCard(props: ConversationPreviewCardProps) {
  const { conversation, url, index } = props;

  const humanParticipant = Object.values(conversation.participants)
    .find((participant) => participant.type === ConversationParticipantType.WEBSITE_USER);

  const hasUnreadMessages = (conversation.metadata?.unreadMessagesCount || 0) > 0;

  return (
    <Link
      to={url}
      key={conversation._id}
      className={styles.card}
    >
      <UserAvatar
        size={32}
        className={styles.avatar}
        fallback={false}
        style={{
          backgroundColor: ColorList[index % ColorList.length],
        }}
      >
        {(humanParticipant?.name || 'U')[0]}
      </UserAvatar>
      <div className="flex-1">
        <div className="d-flex justify-content-between gap-2">
          <p className={styles.title}>
            {humanParticipant?.name || 'User'}
          </p>
          {
            conversation.lastMessage && (
              <p className={cn(styles.time, hasUnreadMessages && 'fw-bold')}>
                {getDisplayTime(new Date(conversation.lastMessage.createdAt))}
              </p>
            )
          }
        </div>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <p className={cn(styles.excerpt, hasUnreadMessages && 'fw-bold')}>
            {getLastMessageText(conversation.lastMessage)}
          </p>
          {
            hasUnreadMessages && (
              <div className={cn(styles.badge, 'd-flex')}>
                {conversation.metadata?.unreadMessagesCount}
              </div>
            )
          }
        </div>
      </div>
    </Link>
  );
}
