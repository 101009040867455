import { Store } from 'pullstate';
import ConversationParticipant from '@zupport/types/models/ConversationParticipant';
import Agent from '@zupport/types/models/Agent';

interface InboxState {
  participant: ConversationParticipant | null;
  agent: Agent | null;
  agents: Agent[];
}

const InboxStore = new Store<InboxState>({
  participant: null,
  agent: null,
  agents: [],
});

export default InboxStore;
