import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SimpleBar from 'simplebar-react';
import ConversationIntent from '@zupport/types/models/ConversationIntent';
import Conversation from '@zupport/types/models/Conversation';
import useAgents from '../../hooks/useAgents';
import Select from '../../../common/components/Select';
import UserAvatar from '../../../common/components/UserAvatar';
import styles from './index.module.scss';
import Button from '../../../common/components/Button';
import RightSidebarSection from '../../../common/components/RightSidebarSection';
import ConversationInfoUsersList from '../ConversationInfoUsersList';
import ConversationInfoNotes from '../ConversationInfoNotes';
import ConversationInfoIntents from '../ConversationInfoIntents';
import useConversationPermissions from '../../hooks/useConversationPermissions';
import useRequest from '../../../common/hooks/useRequest';
import api from '../../../api';

// Temporarily hide intents
const SHOW_INTENTS = false;

interface ConversationInfoProps {
  conversation: Conversation;
  onUpdate: (conversation: Conversation) => void;
  onIntentCreated: (intent: ConversationIntent) => void;
  onIntentUpdated: (intent: ConversationIntent) => void;
  onIntentRemoved: (intent: ConversationIntent) => void;
  onGoBackClick?: () => void;
}

export default function ConversationInfo(props: ConversationInfoProps) {
  const {
    conversation, onIntentCreated, onIntentUpdated, onIntentRemoved, onGoBackClick, onUpdate,
  } = props;
  const agents = useAgents();
  const [showNoteForm, setShowNoteForm] = useState(false);
  const permissions = useConversationPermissions(conversation);

  const patch = useRequest(
    async (data: Partial<Pick<Conversation, 'assigneeId'>>) => {
      if (data.assigneeId !== undefined) {
        onUpdate({
          ...conversation,
          assigneeId: data.assigneeId,
        });
      }

      return api.conversations.patch(conversation._id, data);
    },
  );

  return (
    <>
      <div className={styles.header}>
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <Button
                className="mobile-only"
                onClick={onGoBackClick}
                size="small"
                type="text"
                shape="circle"
              >
                <LeftOutlined />
              </Button>
              <p className="m-0 p-0 fw-bold">
                Assignee
              </p>
            </div>
            <Select
              placeholder="Select assignee"
              onChange={(assigneeId) => {
                patch.submit({ assigneeId });
              }}
              options={[
                {
                  label: 'Unassigned',
                  value: null,
                  icon: (
                    <UserAvatar size={20}>
                      U
                    </UserAvatar>
                  ),
                },
                ...agents.map((agent) => ({
                  label: agent.name,
                  value: agent._id,
                  icon: (
                    <UserAvatar avatar={agent.avatar} size={20}>
                      {agent.name[0]}
                    </UserAvatar>
                  ),
                })),
              ]}
              value={conversation.assigneeId || null}
              variant="borderless"
              popupMatchSelectWidth={false}
              size="small"
              showSearch
              disabled={!permissions.reassign}
            />
          </div>
        </div>
      </div>
      <SimpleBar className={styles.content}>
        {
          SHOW_INTENTS && (
            <ConversationInfoIntents
              conversation={conversation}
              onIntentCreated={onIntentCreated}
              onIntentUpdated={onIntentUpdated}
              onIntentRemoved={onIntentRemoved}
            />
          )
        }
        <RightSidebarSection title="Users">
          <ConversationInfoUsersList conversationId={conversation._id} />
        </RightSidebarSection>
        <RightSidebarSection
          title="Notes"
          leftActions={(
            <Button
              size="small"
              shape="circle"
              type="link"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowNoteForm(true);
              }}
            >
              <PlusCircleOutlined />
            </Button>
          )}
        >
          <ConversationInfoNotes
            conversationId={conversation._id}
            showNoteForm={showNoteForm}
            setShowNoteForm={setShowNoteForm}
          />
        </RightSidebarSection>
      </SimpleBar>
    </>
  );
}
