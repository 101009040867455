import { useState } from 'react';
import { FormInstance, notification } from 'antd';
import { AxiosError } from 'axios';

export type InternalNamePath = (string | number)[];
export type ErrorField = {
  name: InternalNamePath;
  errors: string[];
};

type Callback<T> = (...args: any) => Promise<T>;
type Options<T> = Partial<{
  onSuccess: (response: T) => void;
  onError: (e: any, errorFields: ErrorField[]) => void;
  form?: FormInstance<T>;
  callValidateOnFailure?: boolean;
}>;

type UseRequest = {
  loading: boolean;
  submit: (...args: any) => Promise<void>;
};

function extractErrorFields(e: unknown): ErrorField[] {
  const fields: ErrorField[] = [];

  if (e instanceof AxiosError && e.response) {
    const { errors } = e.response.data;

    if (errors) {
      Object.keys(errors).forEach((key: string) => {
        const field: ErrorField = {
          name: key.indexOf('.') > -1 ? key.split('.') : [key],
          errors: [errors[key]],
        };
        fields.push(field);
      });
    }
  }

  return fields;
}

export default function useRequest<T>(callback: Callback<T>, options: Options<T> = {}): UseRequest {
  const {
    onSuccess, onError, form,
  } = options;
  const [loading, setLoading] = useState(false);

  const submit = async (...args: any) => {
    setLoading(true);

    try {
      const response = await callback(...args);
      if (onSuccess) {
        onSuccess(response);
      }
    } catch (e: any) {
      const errorFields = extractErrorFields(e);
      let message = e.message || 'Please try again later';

      if (e instanceof AxiosError && e.response) {
        const { errors } = e.response.data;

        if (errors && form) {
          // @ts-ignore
          form.setFields(errorFields);
        }

        message = e.response.data.message || 'Something went wrong';
      }

      if (onError) {
        onError(e, errorFields);
      } else {
        notification.error({
          message,
        });
      }

      setLoading(false);
      throw e;
    }

    setLoading(false);
  };

  return {
    loading,
    submit,
  };
}
