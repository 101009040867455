import {
  createBrowserRouter, Outlet, RouterProvider, useMatch, useNavigate,
} from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import AITool, {
  AIToolEnvironment,
  AIToolExecutionMode,
  AIToolMode,
} from '@zupport/types/types/AITool';
import { guestRoutes } from './GuestNavigator';
import { appRoutes } from './AppNavigator';
import useScript from '../../common/hooks/useScript';
import useUser from '../../auth/hooks/useUser';
import { AuthStore } from '../../auth/state';
import useAuth from '../../auth/hooks/useAuth';
import { IS_PRODUCTION, REACT_APP_API_URL, REACT_APP_ZUPPORT_WEBSITE_UID } from '../../config/env';
import InvitePage from '../../auth/pages/InvitePage';
import useActiveWorkspace from '../../workspace/hooks/useActiveWorkspace';
import UserTracking from '../../tracking/components/UserTracking';
import { getToken } from '../../auth/utils';

const changeEmail: AITool = {
  id: 'change-email',
  name: 'Change Email',
  description: 'Change email of a user',
  mode: AIToolMode.WRITE,
  executionMode: AIToolExecutionMode.MANUAL,
  needsConfirmation: false,
  environment: AIToolEnvironment.CLIENT,
  request: {
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/users/me`,
    data: {
      email: '{{inputs.email}}',
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  },
  inputs: {
    email: {
      type: 'string',
      placeholder: 'Please enter the new email',
      required: true,
      schema: {
        type: 'string',
        format: 'email',
      },
    },
  },
  confirmButtonText: 'Change email',
  feedback: {
    success: {
      title: 'Your email has been changed',
      subtitle: 'Your new email is {{inputs.email}}',
    },
    error: {
      title: 'Failed to change email',
      subtitle: 'Please try again',
    },
  },
};
const changePassword: AITool = {
  id: 'change-password',
  name: 'Change Password',
  description: 'Change the user account password',
  mode: AIToolMode.WRITE,
  executionMode: AIToolExecutionMode.MANUAL,
  needsConfirmation: false,
  environment: AIToolEnvironment.CLIENT,
  request: {
    method: 'PATCH',
    url: `${REACT_APP_API_URL}/users/me`,
    data: {
      password: '{{inputs.password}}',
      newPassword: '{{inputs.newPassword}}',
    },
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  },
  inputs: {
    password: {
      type: 'string',
      placeholder: 'Please enter your current password',
      required: true,
      sensitive: true,
    },
    newPassword: {
      type: 'string',
      placeholder: 'Please enter your new password',
      required: true,
      sensitive: true,
      schema: {
        type: 'string',
        minLength: 8,
      },
    },
  },
  confirmButtonText: 'Change Password',
  feedback: {
    success: {
      title: 'Password changed',
      subtitle: 'You can login with your new password',
    },
    error: {
      title: 'Failed to change password',
      subtitle: '{{response.message}}',
    },
  },
};

const userTools: AITool[] = [changeEmail, changePassword];
const publicTools: AITool[] = [];

function Content() {
  useScript(!IS_PRODUCTION ? 'http://localhost:3100/widget.js' : 'https://app.zupport.ai/widget.js');

  const user = useUser();
  const navigate = useNavigate();
  const token = AuthStore.useState((s) => s.token);
  const authenticated = AuthStore.useState((s) => s.authenticated);
  const isVerifyEmailPage = useMatch('/verify-email');
  const workspace = useActiveWorkspace();
  const auth = useAuth();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    auth.authenticate().then(() => {
      setInitialized(true);
    }).catch(() => {
      notification.error({
        message: 'Authentication failed',
        description: 'Please login again',
      });
    });
  }, [token]);

  useEffect(() => () => {
    setInitialized(false);
  }, [authenticated]);

  useEffect(() => {
    // @ts-ignore
    window.zupportSettings = {
      baseAPI: REACT_APP_API_URL,
      websiteUid: REACT_APP_ZUPPORT_WEBSITE_UID,
      userUid: user?._id,
      userEmail: user?.email,
      userSecret: user?._id,
      userFirstName: user?.firstName,
      userLastName: user?.lastName,
      company: workspace ? {
        id: workspace._id,
        name: workspace.name,
        mrr: workspace.mrr,
      } : undefined,
      companyList: user?.workspaceIds,
      tools: user ? userTools : publicTools,
    };
  }, [user, workspace]);

  if (!initialized) {
    return null;
  }

  if (user && !user.verified && !isVerifyEmailPage) {
    navigate('/verify-email');
    return null;
  }

  return (
    <>
      <UserTracking />
      <Outlet />
    </>
  );
}

export default function Navigator() {
  const { authenticated } = useAuth();

  const router = useMemo(() => createBrowserRouter([
    {
      path: '/invites/:workspaceUserId/:token',
      element: <InvitePage />,
    },
    {
      element: <Content />,
      children: authenticated ? appRoutes : guestRoutes,
    },
  ]), [authenticated]);

  return (<RouterProvider router={router} />);
}
