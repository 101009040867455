import { Card } from 'antd';
import NoteForm, { NoteFormProps } from './NoteForm';

interface NoteFormCardProps extends NoteFormProps {}

export default function NoteFormCard(props: NoteFormCardProps) {
  const { entityId, entityType, ...rest } = props;

  return (
    <Card size="small">
      <NoteForm
        entityId={entityId}
        entityType={entityType}
        {...rest}
      />
    </Card>
  );
}
