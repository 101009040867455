import { ConversationIntentStatus } from '@zupport/types/models/ConversationIntent';
import Select, { SelectOption, SelectProps } from '../../../common/components/Select';

const options: SelectOption[] = [
  {
    label: 'Open',
    value: ConversationIntentStatus.OPEN,
  },
  {
    label: 'In Progress',
    value: ConversationIntentStatus.IN_PROGRESS,
  },
  {
    label: 'Closed',
    value: ConversationIntentStatus.CLOSED,
  },
];

interface ConversationIntentStatusSelectProps extends Omit<SelectProps, 'options'> {}

export default function ConversationIntentStatusSelect(
  props: ConversationIntentStatusSelectProps,
) {
  return (
    <Select
      options={options}
      placeholder="Select status"
      {...props}
    />
  );
}
