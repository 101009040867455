import User from '@zupport/types/models/User';
import BaseService from './BaseService';

export default class Users extends BaseService {
  me(): Promise<User> {
    return this._get('/users/me');
  }

  patchMe(data: Partial<User>): Promise<User> {
    return this._patch('/users/me', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  verifyEmail(verificationCode: string): Promise<User> {
    return this._post('/users/verify-email', { verificationCode });
  }

  resendVerificationEmail(): Promise<void> {
    return this._post('/users/resend-verification-email');
  }
}
