import ConversationMessage from '@zupport/types/models/ConversationMessage';
import { MessageBlockType } from '@zupport/types/types/MessageBlock';
import cn from '../../../common/utils/cn';
import styles from './index.module.scss';
import UserAvatar from '../../../common/components/UserAvatar';
import TextMessageView from './TextMessageView';

export interface ConversationMessageViewProps {
  message: Pick<ConversationMessage, 'message' | 'blocks' | 'participant' | 'createdAt'>;
  own: boolean;
  divergent: boolean;
}

function getDisplayTime(date: Date) {
  const isToday = new Date().toDateString() === date.toDateString();

  if (isToday) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  const isDifferentYear = new Date().getFullYear() !== date.getFullYear();

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: isDifferentYear ? 'numeric' : undefined,
  });
}

export default function ConversationMessageView(props: ConversationMessageViewProps) {
  const { message, own, divergent } = props;
  const { message: messageContent, blocks } = message;

  return (
    <div
      className={cn(
        'test',
        styles.container,
        own && styles.containerOwn,
        divergent && styles.containerDivergent,
      )}
    >
      <UserAvatar
        className={styles.avatar}
        avatar={message.participant.avatar}
        fallback={false}
        size={28}
      >
        {message.participant.name[0]}
      </UserAvatar>
      {
        messageContent && (
          <TextMessageView
            block={{
              id: '',
              type: MessageBlockType.TEXT,
              content: messageContent,
            }}
            own={own}
          />
        )
      }
      {
        blocks && blocks.map((block) => {
          if (block.type === MessageBlockType.TEXT) {
            return (
              <TextMessageView block={block} own={own} />
            );
          }

          return null;
        })
      }
      <div className={styles.timestamp}>
        {getDisplayTime(new Date(message.createdAt))}
      </div>
    </div>
  );
}
