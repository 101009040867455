import { useNavigate, useSearchParams } from 'react-router-dom';
import { Drawer } from 'antd';
import GlobalWebsiteUserModalContent from './GlobalWebsiteUserModalContent';
import { GLOBAL_WEBSITE_USER_MODAL_ID_PARAM } from '../../constants';

export default function GlobalWebsiteUserModal() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const id = params.get(GLOBAL_WEBSITE_USER_MODAL_ID_PARAM);

  return (
    <Drawer
      open={!!id}
      onClose={() => {
        navigate(-1);
      }}
      title="User Details"
      styles={{
        body: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
      {
        id && (
          <GlobalWebsiteUserModalContent websiteUserId={id} />
        )
      }
    </Drawer>
  );
}
