import {
  Form, message, Popconfirm, Switch, Tabs,
} from 'antd';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AppSubPageLayoutContent } from '../../../layout/components/AppSubPageLayout';
import useActiveHelpCenter from '../../hooks/useActiveHelpCenter';
import HelpCenterArticleLocalizedContentForm from '../../components/HelpCenterArticleLocalizedContentForm';
import api, {
  HelpCenter,
  HelpCenterArticle,
  HelpCenterArticleContent,
  HelpCenterArticleData,
  HelpCenterArticleStatus,
  UploadType,
} from '../../../api';
import useQuery from '../../../common/hooks/useQuery';
import Spinner from '../../../common/components/Spinner';
import { getLanguageName } from '../../../common/config/languages';
import useRequest, { ErrorField } from '../../../common/hooks/useRequest';
import Button from '../../../common/components/Button';
import { slugify } from '../../../common/utils/url';
import HelpCenterArticleSelect from '../../components/HelpCenterArticleSelect';
import RightSidebarSection from '../../../common/components/RightSidebarSection';

const defaultContent: HelpCenterArticleContent = {
  title: '',
  slug: '',
  description: '',
  html: '<p></p>',
  content: [
    {
      type: 'paragraph',
      props: {
        textColor: 'default',
        backgroundColor: 'default',
        textAlignment: 'left',
      },
      content: [],
      children: [],
    },
  ],
};

function getInitialValue(
  helpCenter: HelpCenter,
  article?: HelpCenterArticle,
): HelpCenterArticleData {
  if (article) {
    return article;
  }

  const content: HelpCenterArticle['content'] = {};

  helpCenter.config.languages.available.forEach((language) => {
    content[language] = { ...defaultContent };
  });

  return {
    status: HelpCenterArticleStatus.DRAFT,
    content,
    isCategory: false,
    helpCenterId: helpCenter._id,
    useInAI: true,
  };
}

interface HelpCenterArticleEditorPageProps {
  article?: HelpCenterArticle;
  createIfNotExists?: boolean;
}

export default function HelpCenterArticleEditorPage(props: HelpCenterArticleEditorPageProps) {
  const { article: propsArticle, createIfNotExists } = props;
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const helpCenter = useActiveHelpCenter();

  const [status, setStatus] = useState<HelpCenterArticleStatus>(
    propsArticle?.status || HelpCenterArticleStatus.DRAFT,
  );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const article = useQuery(
    async () => {
      if (propsArticle) {
        return propsArticle;
      }

      if (helpCenter && createIfNotExists) {
        const result = await api.helpCenters.articles.create(getInitialValue(helpCenter));
        navigate(`../articles/${result._id}`, { replace: true });
        return result;
      }

      return null;
    },
    [propsArticle?._id, helpCenter?._id, createIfNotExists],
  );

  const [form] = Form.useForm();
  const [validateTrigger, setValidateTrigger] = useState<string | undefined>(
    article.data?.status === HelpCenterArticleStatus.PUBLISHED
      ? 'onChange'
      : 'onSubmit',
  );

  const [activeKey, setActiveKey] = useState<string | undefined>(
    helpCenter?.config?.languages?.default,
  );

  const languageList = useMemo(() => (helpCenter?.config.languages
    ? ([...helpCenter.config.languages.available]) : []).sort((a, b) => {
    if (a === helpCenter?.config.languages.default) {
      return -1;
    }

    if (b === helpCenter?.config.languages.default) {
      return 1;
    }

    return a.localeCompare(b);
  }), [helpCenter]);

  useEffect(() => {
    if (helpCenter) {
      setActiveKey(helpCenter.config.languages.default);
    }
  }, [helpCenter]);

  useEffect(() => {
    if (propsArticle) {
      setStatus(propsArticle.status);
      article.setData(propsArticle);
      setValidateTrigger(propsArticle.status === HelpCenterArticleStatus.PUBLISHED ? 'onChange' : 'onSubmit');
    }
  }, [propsArticle?.status]);

  useEffect(() => {
    if (article.data) {
      form.setFieldsValue(article.data);
    }
  }, [article.data?._id]);

  const deleteRequest = useRequest(
    async () => {
      if (!article.data) {
        throw new Error('Article not found');
      }

      return api.helpCenters.articles.delete(article.data._id);
    },
    {
      onSuccess: () => {
        // https://reactrouter.com/en/main/hooks/use-navigate#optionsrelative
        navigate(new URL('.', window.origin + window.location.pathname).pathname, { replace: true });
        message.success('Article deleted');
      },
    },
  );

  const saveRequest = useRequest(
    async () => {
      if (!article.data) {
        throw new Error('Article not found');
      }

      if (status === HelpCenterArticleStatus.PUBLISHED) {
        try {
          await form.validateFields();
        } catch (e) {
          form.submit();
          throw e;
        }
      }

      const values = form.getFieldsValue();
      return api.helpCenters.articles.patch(article.data._id, values);
    },
    {
      onSuccess: (result) => {
        setHasUnsavedChanges(false);
        article.setData(result);
        form.setFieldsValue(result);
        message.success('Article saved');
      },
      onError: () => {},
    },
  );

  const patchRequest = useRequest(
    async (values: Partial<HelpCenterArticleData>) => {
      if (!article.data) {
        throw new Error('Article not found');
      }

      try {
        article.setData((prev) => {
          if (!prev) {
            return prev;
          }

          Object.entries(values).forEach(([key, value]) => {
            form.setFieldValue(key as any, value);
          });

          return { ...prev, ...values };
        });
        return await api.helpCenters.articles.patch(article.data._id, values);
      } catch (e) {
        article.setData(article.data);
        throw e;
      }
    },
    {
      onSuccess: (result) => {
        article.setData(result);
      },
    },
  );

  const handleFormFailure = useCallback((errorFields?: ErrorField[]) => {
    let language: string | undefined;
    let i = 0;

    if (errorFields) {
      while (i < errorFields.length) {
        const error = errorFields[i];
        if (Array.isArray(error.name) && error.name[0] === 'content') {
          language = String(error.name[1]);
          break;
        }

        i += 1;
      }
    }

    if (language && languageList.indexOf(language) !== -1) {
      setActiveKey(language);
      message.error(`The ${getLanguageName(language)} article has errors`);
    }

    setValidateTrigger(undefined);
  }, [languageList]);

  const publishRequest = useRequest(
    async (values) => {
      if (!article.data) {
        throw new Error('Article not found');
      }

      return api.helpCenters.articles.patch(article.data._id, {
        ...values,
        status: HelpCenterArticleStatus.PUBLISHED,
      });
    },
    {
      onSuccess: () => {
        setStatus(HelpCenterArticleStatus.PUBLISHED);
        message.success('Article published');
      },
      form,
      onError: (_, errorFields) => {
        handleFormFailure(errorFields);
      },
    },
  );

  const unpublishRequest = useRequest(
    async () => {
      if (!article.data) {
        throw new Error('Article not found');
      }

      return api.helpCenters.articles.patch(article.data._id, {
        status: HelpCenterArticleStatus.DRAFT,
      });
    },
    {
      onSuccess: () => {
        setStatus(HelpCenterArticleStatus.DRAFT);
        message.success('Article unpublished');
      },
    },
  );

  const onTitleChange = (language: string, value: string) => {
    form.setFieldValue(
      ['content', language, 'slug'],
      slugify(value),
    );
  };

  const onHTMLChange = (language: string, value: string) => {
    form.setFieldValue(
      ['content', language, 'html'],
      value,
    );
  };

  const uploadFile = useCallback(async (file: File): Promise<string> => {
    if (!article.data) {
      message.error('Article not found');
      throw new Error('Article not found');
    }

    try {
      const result = await api.uploads.create({
        file,
        type: UploadType.IMAGE,
        workspaceId: article.data.workspaceId,
        helpCenterId: article.data.helpCenterId,
      });

      return result.url;
    } catch (e) {
      message.error('Failed to upload image, please try again');
      throw e;
    }
  }, [article.data]);

  if (!helpCenter) {
    return null;
  }

  if (article.loading) {
    return (
      <div className="mt-2 flex-1">
        <Spinner center />
      </div>
    );
  }

  // Make sure we do not render the form if the article does not exist
  // and is not in create mode.
  // Otherwise it will break the editor since it will try to replace the
  // initial content with the new one and it won't be able to find the previous
  // ids
  if (!article.data && !createIfNotExists) {
    return null;
  }

  return (
    <Form<HelpCenterArticleData>
      onFinish={publishRequest.submit}
      validateTrigger={validateTrigger}
      className="flex-1"
      layout="vertical"
      onFinishFailed={(data) => {
        handleFormFailure(data.errorFields);
      }}
      initialValues={getInitialValue(helpCenter, article.data || undefined)}
      form={form}
      onValuesChange={() => {
        setHasUnsavedChanges(true);
      }}
    >
      <AppSubPageLayoutContent
        title={propsArticle ? 'Edit Article' : 'Create Article'}
        titleAction={(
          <div className="d-flex align-items-center justify-content-center gap-3">
            {
              hasUnsavedChanges && (
                <Button
                  type="default"
                  onClick={saveRequest.submit}
                  loading={saveRequest.loading}
                >
                  Save Changes
                </Button>
              )
            }
            {
              status === HelpCenterArticleStatus.DRAFT ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={publishRequest.loading}
                >
                  Publish
                </Button>
              ) : (
                <Button
                  type="default"
                  loading={unpublishRequest.loading}
                  onClick={unpublishRequest.submit}
                >
                  Un-Publish
                </Button>
              )
            }
            <Popconfirm
              title="Are you sure?"
              description={(
                <div>
                  You can unpublish this article
                  <br />
                  if you want to hide it from the public
                </div>
              )}
              open={deleteOpen}
              onCancel={() => { setDeleteOpen(false); }}
              okButtonProps={{
                children: 'Delete Article',
                loading: deleteRequest.loading,
                danger: true,
              }}
              okText="Delete Article"
              onConfirm={deleteRequest.submit}
            >
              <Button
                danger
                onClick={() => { setDeleteOpen(true); }}
              >
                Delete
              </Button>
            </Popconfirm>
          </div>
        )}
        content={(
          <div style={{ maxWidth: 1200, margin: 'auto', padding: '0 54px 108px 54px' }}>
            {
              helpCenter.config.languages.enable && (
                <div>
                  <Tabs
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    items={[
                      ...helpCenter.config.languages.available,
                    ].sort((a, b) => {
                      if (a === helpCenter.config.languages.default) {
                        return -1;
                      }

                      if (b === helpCenter.config.languages.default) {
                        return 1;
                      }

                      return a.localeCompare(b);
                    }).map((language) => ({
                      key: language,
                      label: getLanguageName(language),
                      children: (
                        <HelpCenterArticleLocalizedContentForm
                          isActive={language === activeKey}
                          key={String(article?.data?.updatedAt || undefined)}
                          language={language}
                          onTitleChange={(title) => {
                            onTitleChange(language, title);
                          }}
                          onHTMLChange={(html) => {
                            onHTMLChange(language, html);
                          }}
                          uploadFile={uploadFile}
                        />
                      ),
                      forceRender: true,
                    }))}
                  />
                </div>
              )
            }
            {
              !helpCenter.config.languages.enable && (
                <HelpCenterArticleLocalizedContentForm
                  isActive
                  key={String(article?.data?.updatedAt || undefined)}
                  language="en"
                  onTitleChange={(title) => {
                    onTitleChange('en', title);
                  }}
                  onHTMLChange={(html) => {
                    onHTMLChange('en', html);
                  }}
                  uploadFile={uploadFile}
                />
              )
            }
          </div>
        )}
        sidebar={(
          <div>
            <RightSidebarSection title="Category">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  Use as Category
                </div>
                <Switch
                  checked={article.data?.isCategory}
                  onChange={(checked) => {
                    patchRequest.submit({ isCategory: checked });
                  }}
                />
              </div>
              <div className="text-secondary text-sm mt-1">
                Use this article as a category to group other articles
              </div>
              <div className="mt-4">
                <div>
                  Category
                </div>
                <HelpCenterArticleSelect
                  helpCenterId={helpCenter._id}
                  defaultLanguage={helpCenter.config.languages.default}
                  className="w-100"
                  value={article.data?.parentId}
                  onChange={(parentId) => {
                    patchRequest.submit({ parentId: parentId || null });
                  }}
                  size="small"
                  isCategory
                />
                <div className="text-secondary text-sm mt-1">
                  Select a category to group this article under
                </div>
              </div>
            </RightSidebarSection>
            <RightSidebarSection title="AI">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  Use in AI Bots
                </div>
                <Switch
                  checked={article.data?.useInAI}
                  onChange={(checked) => {
                    patchRequest.submit({ useInAI: checked });
                  }}
                />
              </div>
              <div className="text-secondary text-sm mt-1">
                Choose whether to use this article in AI bots
                and serve it as a response to matching user queries
              </div>
            </RightSidebarSection>
            <RightSidebarSection title="Related articles">
              <div>
                Previous Article
              </div>
              <HelpCenterArticleSelect
                helpCenterId={helpCenter._id}
                defaultLanguage={helpCenter.config.languages.default}
                className="w-100"
                value={article.data?.previousArticleId}
                onChange={(parentId) => {
                  patchRequest.submit({ previousArticleId: parentId || null });
                }}
                size="small"
              />
              <div className="text-secondary text-sm mt-1">
                Will be shown as previous article in the article page
              </div>
              <div className="mt-2">
                Next Article
              </div>
              <HelpCenterArticleSelect
                helpCenterId={helpCenter._id}
                defaultLanguage={helpCenter.config.languages.default}
                className="w-100"
                value={article.data?.nextArticleId}
                onChange={(parentId) => {
                  patchRequest.submit({ nextArticleId: parentId || null });
                }}
                size="small"
              />
              <div className="text-secondary text-sm mt-1">
                Will be shown as next article in the article page
              </div>
            </RightSidebarSection>
          </div>
        )}
      />
    </Form>
  );
}
