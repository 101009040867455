import Agent from '@zupport/types/models/Agent';
import BaseModel from './BaseModel';
import LocalizedValue from './LocalizedValue';

export enum HelpCenterArticleStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export interface HelpCenterArticleContent {
  title: string;
  slug: string;
  description: string;
  html: string;
  content: Record<string, any>[];
}

export interface HelpCenterArticleData {
  /**
   * The status of the article.
   */
  status: HelpCenterArticleStatus;

  /**
   * The content of the article.
   * One object per language.
   */
  content: LocalizedValue<HelpCenterArticleContent>;

  /**
   * Whether this article is a category.
   * We are using articles as categories to make it easier to manage the help center.
   */
  isCategory: boolean;

  /**
   * The parent category of this article (if any).
   */
  parentId?: BaseModel['_id'];

  previousArticleId?: BaseModel['_id'];

  nextArticleId?: BaseModel['_id'];

  /**
   * The help center this article belongs to.
   */
  helpCenterId: BaseModel['_id'];

  /**
   * Whether to use this article in the AI bots.
   */
  useInAI: boolean;
}

export default interface HelpCenterArticle extends HelpCenterArticleData, BaseModel {
  /**
   * The author of the article.
   */
  author: Agent;

  /**
   * The date when the article was published.
   */
  publishedAt: Date;

  /**
   * The workspace this article belongs to.
   */
  workspaceId: BaseModel['_id'];

  /**
   * The number of child articles this article has.
   */
  articlesCount: number;
}
