import React from 'react';
import {
  AuditOutlined, ClockCircleOutlined, DollarOutlined, UserOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useQuery from '../../../common/hooks/useQuery';
import api, { WebsiteUser } from '../../../api';
import { getDisplayTime } from '../ConversationPreviewCard';
import WebsiteUserInfoItem from '../../../website-users/components/WebsiteUserInfoItem';
import EmailIcon from '../../../common/components/Icons/EmailIcon';
import Button from '../../../common/components/Button';
import useURLBuilder from '../../../common/hooks/useURLBuilder';
import { GLOBAL_WEBSITE_USER_MODAL_ID_PARAM } from '../../../website-users/constants';

interface ConversationInfoUsersListProps {
  conversationId: string;
}

export default function ConversationInfoUsersList(props: ConversationInfoUsersListProps) {
  const { conversationId } = props;
  const buildUrl = useURLBuilder();

  const query = useQuery<WebsiteUser[]>(
    async () => api.conversations.getUsers(conversationId),
    [conversationId],
  );

  return (
    <div>
      {
        query.data && query.data.length && (
          <>
            {
              query.data.map((user) => (
                <React.Fragment key={user._id}>
                  <WebsiteUserInfoItem
                    icon={<UserOutlined />}
                    label="Name"
                    value={`${user.firstName} ${user.lastName}`}
                  />
                  <WebsiteUserInfoItem
                    icon={<EmailIcon />}
                    label="Email"
                    value={user.email}
                  />
                  <WebsiteUserInfoItem
                    icon={<ClockCircleOutlined />}
                    label="Last Seen"
                    value={getDisplayTime(new Date(user.lastSeenAt))}
                  />
                  <WebsiteUserInfoItem
                    icon={<AuditOutlined />}
                    label="Company"
                    value={user.company?.name || user.company?.id || 'Unknown'}
                  />
                  <WebsiteUserInfoItem
                    icon={<DollarOutlined />}
                    label="MRR"
                    value={user.company?.mrr ? Number(user.company.mrr).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'usd',
                    }) : 'Unknown'}
                  />
                  {
                    user.location?.country && (
                      <div className="d-flex align-items-center justify-content-between">
                        <span>Location</span>
                        <span className="d-flex align-items-center gap-2">
                          {
                                user.location.country && (
                                  <span>
                                    {
                                      user.location.city && `${user.location.city}, `
                                    }
                                    {user.location.country}
                                  </span>
                                )
                              }
                          {
                            user.location.countryCode && (
                              <img
                                alt="Country Flag"
                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${user.location.countryCode}.svg`}
                                height={13}
                                className="rounded-1"
                              />
                            )
                          }
                        </span>
                      </div>
                    )
                  }
                  <div className="d-flex justify-content-center mt-2">
                    <Link to={buildUrl({ [GLOBAL_WEBSITE_USER_MODAL_ID_PARAM]: user._id })}>
                      <Button size="small" type="link">
                        Chat History
                      </Button>
                    </Link>
                  </div>
                </React.Fragment>
              ))
            }
          </>
        )
      }
    </div>
  );
}
