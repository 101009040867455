import Conversation from '@zupport/types/models/Conversation';
import usePermissions from '../../permissions/hooks/usePermissions';
import useAgent from './useAgent';

interface UseConversationPermissions {
  reply: boolean;
  reassign: boolean;
}

export default function useConversationPermissions(
  conversation?: Conversation,
): UseConversationPermissions {
  const permissions = usePermissions();
  const agent = useAgent();

  let reply = false;
  let reassign = Boolean(conversation && !conversation.assigneeId);

  if (conversation) {
    if (permissions.conversations.reassign.any) {
      reassign = true;
    } else if (
      permissions.conversations.reassign.own
      && conversation.assigneeId
      && conversation.assigneeId === agent?._id
    ) {
      reassign = true;
    }

    if (permissions.conversations.reply.any) {
      reply = true;
    } else if (
      permissions.conversations.reply.own
      && conversation.assigneeId
      && conversation.assigneeId === agent?._id
    ) {
      reply = true;
    }
  }

  return {
    reply,
    reassign,
  };
}
