import User from '@zupport/types/models/User';
import BaseService from './BaseService';

export default class Auth extends BaseService {
  async signUp(data: Pick<User, 'firstName' | 'lastName' | 'password' | 'email'>): Promise<User> {
    return this._post<typeof data, User>('/auth/sign-up', data);
  }

  async signIn(email: User['email'], password: User['password']): Promise<{ user: User; token: string }> {
    return this._post<{ email: User['email']; password: User['password'] }, { user: User; token: string }>(
      '/auth/sign-in',
      { email, password },
    );
  }
}
