import { Store } from 'pullstate';
import User from '@zupport/types/models/User';
import { getToken, getUser } from '../utils';

export type AuthStoreState = {
  authenticated: boolean;
  user?: User;
  token?: string | null;
};

export const AuthStore = new Store<AuthStoreState>({
  authenticated: !!getToken(),
  user: getUser(),
  token: getToken(),
});
