import Agent from '@zupport/types/models/Agent';
import BaseService from './BaseService';

type SummaryReportsParams = {
  from?: string;
  to?: string;
};

export type ConversationsByStatusAndAgent = {
  open: number;
  closed: number;
  agentId?: string;
  agent?: Agent;
};

export type SummaryReports = {
  totalConversationsCount: number;
  totalProactiveConversationsCount: number;
  resolvedConversationsCount: number;
  resolvedConversationsRate: number;
  conversationsByResolution: number;
  conversationsByStatusAndAgent: ConversationsByStatusAndAgent[];
};

export default class Reports extends BaseService {
  async summary(params: SummaryReportsParams): Promise<SummaryReports> {
    return this._get('/reports/summary', { params });
  }
}
