import { Modal } from 'antd';
import Conversation from '@zupport/types/models/Conversation';

interface CloseConversationDialogProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  conversation: Conversation;
  loading?: boolean;
}

export default function CloseConversationDialog(props: CloseConversationDialogProps) {
  const {
    open,
    onCancel,
    conversation,
    onOk,
    loading,
  } = props;

  const openIntentsCount = (conversation.intents?.filter((intent) => intent.status === 'open') || []).length;

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      title="Are you sure you want to close this conversation?"
      okText="Close Conversation"
      okButtonProps={{
        loading,
        disabled: loading,
      }}
    >
      <p>
        Once you close this conversation, you won&apos;t be able to reopen it,
        and the user won&apos;t be able to send any more messages.
      </p>
      {
        openIntentsCount > 0 && (
        <p>
          {' '}
          {openIntentsCount}
          {' '}
          intent(s) are still open.
          Closing the conversation will also close all open intents.
        </p>
        )
      }
    </Modal>
  );
}
