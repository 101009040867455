import {
  Avatar,
  Col, Form, Input, notification, Row, Upload,
} from 'antd';
import { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import useUser from '../../auth/hooks/useUser';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api from '../../api';
import useAuth from '../../auth/hooks/useAuth';
import { FALLBACK_USER_AVATAR } from '../../common/utils/chat';
import { asset } from '../../common/utils/assets';

export default function UserGeneralSettingsPage() {
  const user = useUser();
  const auth = useAuth();
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>(
    user?.avatar ? asset(user.avatar) : FALLBACK_USER_AVATAR,
  );

  const request = useRequest(
    async (data) => api.users.patchMe({
      ...data,
      avatar,
    }),
    {
      onSuccess: (result) => {
        if (result.newEmail && result.newEmail !== user?.email) {
          notification.success({
            message: 'Email Verification Required',
            description: 'Please check your email to verify your new email address',
          });
        } else {
          notification.success({
            message: 'Settings Updated',
          });
        }
        auth.setUser(result);
      },
      form,
    },
  );

  const [verificationCode, setVerificationCode] = useState<string | undefined>();
  const verificationRequest = useRequest(
    async (data) => {
      if (!verificationCode) {
        throw new Error('Please enter a verification code');
      }
      return api.users.verifyEmail(data.verificationCode);
    },
    {
      onSuccess: (result) => {
        notification.success({
          message: 'Email Verified',
        });
        auth.setUser(result);
      },
    },
  );

  return (
    <Form
      className="flex-1"
      layout="vertical"
      requiredMark={false}
      form={form}
      initialValues={{
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      }}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="Settings"
        width="medium"
        content={(
          <Row gutter={[16, 16]}>
            <Col span={24} md={10}>
              <ImgCrop>
                <Upload.Dragger
                  style={{ width: '100%' }}
                  className="w-100"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      setAvatarUrl(e.target?.result as string);
                      setAvatar(file);
                    };
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  <Avatar
                    size={100}
                    src={avatarUrl}
                  />
                </Upload.Dragger>
              </ImgCrop>
            </Col>
            <Col span={24} md={14}>
              <Row gutter={[16, 16]}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your first name',
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your last name',
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your email',
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </Col>
                {
                  user?.newEmail && user.newEmail !== user.email && (
                    <Col span={24} className="d-flex align-items-center">
                      <Form.Item label="Verification Code">
                        <div className="d-flex align-items-center gap-2">
                          <Input
                            placeholder="Verification Code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                          />
                          <Button
                            type="default"
                            loading={verificationRequest.loading}
                            onClick={() => verificationRequest.submit({ verificationCode })}
                          >
                            Verify Email
                          </Button>
                        </div>
                      </Form.Item>
                    </Col>
                  )
                }
              </Row>
            </Col>
          </Row>
        )}
        footer={(
          <div className="d-flex align-items-center justify-content-end flex-1">
            <Form.Item noStyle>
              <Button
                type="primary"
                loading={request.loading}
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        )}
      />
    </Form>
  );
}
