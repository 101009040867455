import { Card, message, Popconfirm } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import MessengerWidgetConfiguration from '@zupport/types/models/MessengerWidgetConfiguration';
import { useState } from 'react';
import AppPageContent from '../../layout/components/AppPageContent';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import useActiveWebsite from '../../website/hooks/useActiveWebsite';
import api from '../../api';
import useActiveWorkspaceId from '../../workspace/hooks/useActiveWorkspaceId';
import useQuery from '../../common/hooks/useQuery';
import Spinner from '../../common/components/Spinner';
import PermissionChecker from '../../common/components/PermissionChecker';
import usePermissions from '../../permissions/hooks/usePermissions';

export default function MessengerWidgetConfigurationsPage() {
  const navigate = useNavigate();
  const website = useActiveWebsite();
  const workspaceId = useActiveWorkspaceId();
  const permissions = usePermissions();
  const [deleteId, setDeleteId] = useState<MessengerWidgetConfiguration['_id'] | null>(null);

  const createRequest = useRequest(async () => {
    if (!website) {
      throw new Error('Website not found');
    }

    if (!workspaceId) {
      throw new Error('Workspace not found');
    }

    return api.messengerWidgetConfigurations.create({
      websiteId: website._id,
      workspaceId,
    });
  }, {
    onSuccess: (response) => {
      navigate(response._id);
    },
  });

  const query = useQuery(
    async () => {
      if (!website) {
        throw new Error('Website not found');
      }
      return api.messengerWidgetConfigurations.list({
        websiteId: website?._id,
      });
    },
  );

  const isEmpty = query.data && query.data.items.length === 0;

  const createButton = (
    <Button loading={createRequest.loading} onClick={createRequest.submit}>
      Create New Configuration
    </Button>
  );

  const removeRequest = useRequest(async () => {
    if (!deleteId) {
      throw new Error('Invalid configuration ID');
    }

    return api.messengerWidgetConfigurations.delete(deleteId);
  }, {
    onSuccess: (result) => {
      query.setData((prevData) => {
        if (!prevData) {
          return prevData;
        }

        message.success('Configuration deleted successfully');
        return {
          ...prevData,
          items: prevData.items.filter((item) => item._id !== result._id),
        };
      });
    },
  });

  return (
    <PermissionChecker allowed={permissions.messenger.widget.configurations}>
      <AppPageContent
        padding="normal"
        layout="centered"
      >
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-end">
          <div>
            <h2>Messenger Widget Configurations</h2>
            <h3 className="text-secondary">
              Create and manage your messenger widget configurations here.
            </h3>
          </div>
          {!isEmpty && createButton}
        </div>
        {
          isEmpty && (
            <div className="d-flex justify-content-center">
              {createButton}
            </div>
          )
        }
        {
          query.loading && (
            <Spinner center />
          )
        }
        {
          query.data && query.data.items.length > 0 && (
            <div>
              {
                query.data.items.map((item) => (
                  <Card key={item._id} className="mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 className="m-0">{`${!item.enabled ? '[Draft] ' : ''}${item.name}`}</h4>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <Link to={item._id}>
                          <Button>
                            Edit
                          </Button>
                        </Link>
                        <Popconfirm
                          title="Are you sure?"
                          description="This action cannot be undone."
                          okText="Delete Configuration"
                          okButtonProps={{
                            danger: true,
                            loading: removeRequest.loading && deleteId === item._id,
                          }}
                          onConfirm={removeRequest.submit}
                          open={deleteId === item._id}
                          onCancel={() => {
                            setDeleteId(null);
                          }}
                        >
                          <Button
                            danger
                            onClick={() => {
                              setDeleteId(item._id);
                            }}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </Card>
                ))
              }
            </div>
          )
        }
      </AppPageContent>
    </PermissionChecker>
  );
}
