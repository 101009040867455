import {
  Col, Form, Input, Modal, notification, Row, Tag,
} from 'antd';
import {
  DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import useQuery from '../../common/hooks/useQuery';
import useActiveWorkspace from '../hooks/useActiveWorkspace';
import UserAvatar from '../../common/components/UserAvatar';
import api, { WorkspaceUser, WorkspaceUserData, WorkspaceUserRole } from '../../api';
import Button from '../../common/components/Button';
import Select from '../../common/components/Select';
import useRequest from '../../common/hooks/useRequest';
import cn from '../../common/utils/cn';
import Dropdown from '../../common/components/Dropdown';
import ManageSubscriptionButton from '../../billing/components/ManageSubscriptionButton';
import useActiveWorkspacePlan from '../hooks/useActiveWorkspacePlan';

function getRoleLabel(role: WorkspaceUserRole) {
  switch (role) {
    case WorkspaceUserRole.OWNER:
      return 'Owner';
    case WorkspaceUserRole.ADMIN:
      return 'Admin';
    case WorkspaceUserRole.CUSTOMER_SUPPORT_AGENT:
      return 'Customer Support Agent';
    case WorkspaceUserRole.SOFTWARE_ENGINEER:
      return 'Software Engineer';
    default:
      return 'Unknown';
  }
}

export default function WorkspaceMembersSettingsPage() {
  const workspace = useActiveWorkspace();
  const { data, setData } = useQuery<WorkspaceUser[]>(async () => {
    if (!workspace) {
      throw new Error('No active workspace');
    }

    const result = await api.workspaces.users.list(workspace._id);
    return result.items;
  }, [workspace?._id]);

  const [form] = Form.useForm();
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [editMember, setEditMember] = useState<WorkspaceUser | null>(null);
  const [deleteMember, setDeleteMember] = useState<WorkspaceUser | null>(null);
  const [membersLimitReachedModalOpen, setMembersLimitReachedModalOpen] = useState(false);

  const request = useRequest(
    async (workspaceUserData: WorkspaceUserData) => {
      if (!workspace) {
        throw new Error('No active workspace');
      }

      if (editMember) {
        return api.workspaces.users.patch(
          workspace._id,
          editMember._id,
          { role: workspaceUserData.role },
        );
      }

      return api.workspaces.users.invite.send(workspace._id, workspaceUserData);
    },
    {
      form,
      onSuccess: (result) => {
        form.resetFields();
        setAddMemberModalOpen(false);
        setEditMember(null);

        if (!editMember) {
          setData((prev) => (prev ? [result, ...prev] : [result]));
          notification.success({
            message: 'Invitation sent',
            description: 'The invitation has been sent to the user',
          });
        } else {
          notification.success({
            message: 'Member updated',
            description: 'The member has been updated',
          });
          setData((prev) => {
            if (!prev) {
              return [result];
            }

            return prev.map((user) => (user._id === result._id ? result : user));
          });
        }
      },
    },
  );

  const plan = useActiveWorkspacePlan();
  // NULL means no limit (can happen in free trial)
  const membersLimitReached = (data?.length || 0) >= (plan.membersLimit);

  return (
    <>
      <AppSubPageLayoutContent
        title="Members"
        titleAction={(
          <Button
            size="small"
            onClick={() => {
              if (!membersLimitReached) {
                setAddMemberModalOpen(true);
              } else {
                setMembersLimitReachedModalOpen(true);
              }
            }}
            icon={(
              <PlusOutlined />
            )}
          >
            Add Member
          </Button>
        )}
        content={(
          <div>
            {
              data && data.map((user, index) => (
                <div
                  className={cn(
                    'd-flex align-items-start align-items-md-center justify-content-between pb-3 flex-column flex-md-row gap-3',
                    index > 0 && 'border-top pt-3',
                  )}
                >
                  <div className="d-flex align-items-center gap-3 flex-1">
                    <UserAvatar avatar={user.avatar} fallback={false} size={40}>
                      {user.firstName[0] + user.lastName[0]}
                    </UserAvatar>
                    <div>
                      <h5 className="text-lg m-0">
                        {user.firstName}
                        {' '}
                        {user.lastName}
                      </h5>
                      <p className="m-0 text-label text-muted">
                        {user.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex-2 d-flex justify-content-center">
                    <p className="m-0 text-muted">
                      {user.accepted ? 'Accepted' : 'Pending'}
                    </p>
                  </div>
                  <div className="flex-1 d-flex justify-content-end gap-2">
                    <Tag
                      className="bg-gray-5 gray-4 px-3 py-1 border-0"
                      style={{ borderRadius: 16 }}
                    >
                      {getRoleLabel(user.role)}
                    </Tag>
                    <Dropdown
                      items={[
                        {
                          key: 'edit',
                          label: 'Edit',
                          icon: <EditOutlined />,
                          onClick: () => {
                            setEditMember(user);
                            form.setFieldsValue(user);
                            setAddMemberModalOpen(true);
                          },
                        },
                        {
                          key: 'delete',
                          label: 'Delete',
                          icon: <DeleteOutlined />,
                          onClick: () => {
                            if (user.role === WorkspaceUserRole.OWNER) {
                              notification.error({
                                message: 'Cannot delete owner',
                                description: 'You cannot  delete the owner of the workspace',
                              });
                            } else {
                              setDeleteMember(user);
                            }
                          },
                        },
                      ]}
                      disabled={user.role === WorkspaceUserRole.OWNER}
                    >
                      <div style={{ transform: 'rotate(90deg)' }}>
                        <MoreOutlined />
                      </div>
                    </Dropdown>
                  </div>
                </div>
              ))
            }
          </div>
        )}
      />
      <Modal
        open={addMemberModalOpen}
        onCancel={() => {
          setAddMemberModalOpen(false);

          setTimeout(() => {
            setEditMember(null);
            form.setFieldsValue({
              firstName: '',
              lastName: '',
              email: '',
              role: undefined,
            });
          }, 500);
        }}
        title={editMember ? 'Edit Member' : 'Add Member'}
        okText={editMember ? 'Save' : 'Invite Member'}
        onOk={form.submit}
        okButtonProps={{
          loading: request.loading,
          disabled: request.loading,
        }}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={request.submit}
          initialValues={{
            ...(editMember || {}),
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'First Name is required',
                  },
                ]}
              >
                <Input
                  placeholder="User First Name"
                  disabled={!!editMember}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: 'Last Name is required',
                  },
                ]}
              >
                <Input
                  placeholder="User Last Name"
                  disabled={!!editMember}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: 'Role is required',
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  options={Object
                    .values(WorkspaceUserRole)
                    .filter((role) => role !== WorkspaceUserRole.OWNER)
                    .map((role) => ({
                      value: role,
                      label: getRoleLabel(role),
                    }))}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                  {
                    type: 'email',
                    message: 'Invalid email',
                  },
                ]}
              >
                <Input
                  placeholder="User Email"
                  disabled={!!editMember}
                />
              </Form.Item>
            </Col>
            <Form.Item style={{ display: 'none' }}>
              <Button htmlType="submit">Submit</Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
      <Modal
        open={membersLimitReachedModalOpen}
        onCancel={() => {
          setMembersLimitReachedModalOpen(false);
        }}
        title="Members Limit Reached"
        footer={(
          <div className="d-flex justify-content-end gap-2">
            <Button
              type="default"
              onClick={() => {
                setMembersLimitReachedModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <ManageSubscriptionButton
              type="primary"
              onClick={() => {
                setMembersLimitReachedModalOpen(false);
              }}
            >
              Add Seats
            </ManageSubscriptionButton>
          </div>
        )}
      >
        <p>
          You have reached the maximum number of members allowed for this workspace.
          {' '}
          Please add more seats to your subscription.
        </p>
      </Modal>
      <Modal
        open={!!deleteMember}
        onCancel={() => {
          setDeleteMember(null);
        }}
        title="Delete Member"
        okText="Delete"
        onOk={async () => {
          if (!workspace || !deleteMember) {
            return;
          }

          await api.workspaces.users.delete(workspace._id, deleteMember._id);
          setData((prev) => {
            if (!prev) {
              return prev;
            }

            return prev.filter((user) => user._id !== deleteMember._id);
          });

          setDeleteMember(null);
        }}
        okButtonProps={{
          loading: request.loading,
          disabled: request.loading,
          danger: true,
        }}
      >
        <p>
          Are you sure you want to delete
          {' '}
          {deleteMember?.firstName}
          {' '}
          {deleteMember?.lastName}
          {' '}
          from the workspace?
        </p>
      </Modal>
    </>
  );
}
