import { AxiosInstance } from 'axios';
import BaseService from './BaseService';
import Paginated from '../types/Paginated';

export default class RESTService<T, Data = T> extends BaseService {
  constructor(axios: AxiosInstance, readonly endpoint: string) {
    super(axios);
  }

  async create(data: Data): Promise<T> {
    return this._post(this.endpoint, data);
  }

  async list(params: Record<string, any>): Promise<Paginated<T>> {
    return this._get(`${this.endpoint}`, { params });
  }

  async get(id: string): Promise<T> {
    return this._get(`${this.endpoint}/${id}`);
  }

  /**
   * @deprecated - use patch instead
   * @param id
   * @param data
   */
  async update(id: string, data: Partial<Data>): Promise<T> {
    return this._patch(`${this.endpoint}/${id}`, data);
  }

  async patch(id: string, data: Partial<Data>): Promise<T> {
    return this._patch(`${this.endpoint}/${id}`, data);
  }

  async delete(id: string): Promise<T> {
    return this._delete(`${this.endpoint}/${id}`);
  }
}
