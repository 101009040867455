import { TextMessageBlock } from '@zupport/types/types/MessageBlock';
import Markdown from 'react-markdown';
import styles from './index.module.scss';
import cn from '../../../../common/utils/cn';

interface TextMessageViewProps {
  block: TextMessageBlock;
  own?: boolean;
}

export default function TextMessageView(props: TextMessageViewProps) {
  const { block, own } = props;
  const { content } = block;

  return (
    <div className={cn(styles.bubble, own && styles.own)}>
      <Markdown>{content}</Markdown>
    </div>
  );
}
