import { asset } from './assets';

export const FALLBACK_USER_AVATAR = asset('/static/img/avatar-placeholder-2.png');
// export const FALLBACK_USER_AVATAR = 'https://avatar.iran.liara.run/public/23';

export function getUserAvatar(avatar?: string | null, fallback = true): string | undefined {
  if (avatar) {
    if (avatar.startsWith('http')) {
      return avatar;
    }

    return asset(avatar);
  }

  if (!fallback) {
    return undefined;
  }

  return FALLBACK_USER_AVATAR;
}
