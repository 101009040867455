"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgentEntityType = exports.AgentType = void 0;
var AgentType;
(function (AgentType) {
    AgentType["AI"] = "ai";
    AgentType["HUMAN"] = "human";
})(AgentType || (exports.AgentType = AgentType = {}));
var AgentEntityType;
(function (AgentEntityType) {
    AgentEntityType["WORKSPACE_USER"] = "workspace-user";
    AgentEntityType["ASSISTANT"] = "assistant";
})(AgentEntityType || (exports.AgentEntityType = AgentEntityType = {}));
