import { useState } from 'react';
import dayjs from 'dayjs';
import {
  Card, Col, Row,
} from 'antd';
import { CheckOutlined, MessageOutlined, SendOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import AppPageContent from '../../layout/components/AppPageContent';
import { RangePickerPopover } from '../../common/components/RangePicker';
import CustomDateSelection, { DatePreset } from '../../common/types/CustomDateSelection';
import useQuery from '../../common/hooks/useQuery';
import useActiveWorkspaceId from '../hooks/useActiveWorkspaceId';
import api from '../../api';
import Spinner from '../../common/components/Spinner';
import FeatureRequestPreviewCard from '../../feature-requests/components/FeatureRequestPreviewCard';
import Button from '../../common/components/Button';
import { ConversationsByStatusAndAgent } from '../../api/services/Reports';
import useActiveWebsite from '../../website/hooks/useActiveWebsite';

function fillIn<T>(items: T[], count: number, fillValue: T): T[] {
  const result: T[] = [];
  // Calculate the nr of empty items to add
  const emptyCount = count - items.length;

  // If we have more items than the count, return the items without any changes
  if (emptyCount < 0) {
    return items;
  }

  let emptySideCount = Math.floor(emptyCount / 2);
  if (emptyCount % 2 !== 0) {
    emptySideCount += 1;
  }

  const totalItems = items.length + (emptySideCount * 2);

  let i = 0;

  // Add items to the left
  while (i < totalItems) {
    const isEmpty = (i < emptySideCount) || (i >= items.length + emptySideCount);

    if (isEmpty) {
      result.push(fillValue);
    } else {
      result.push(items[i - emptySideCount]);
    }

    i += 1;
  }

  return result;
}

interface ConversationsByStatusAndAgentProps {
  data: ConversationsByStatusAndAgent[];
}

function ConversationsByStatusAndAgentChart(props: ConversationsByStatusAndAgentProps) {
  const { data } = props;

  if (data.length === 0) {
    return (
      <p className="text-center fw-bold">
        No data available within the selected date range
      </p>
    );
  }

  const state = {
    series: [{
      name: 'Closed Conversations',
      data: fillIn(
        data.map((item) => item.closed),
        10,
        0,
      ),
      // color: 'rgb(0, 143, 251)',
      color: 'rgb(0, 214, 143)',
    }, {
      name: 'Open Conversations',
      data: fillIn(
        data.map((item) => item.open),
        10,
        0,
      ),
      color: 'red',
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          columnWidth: 40,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
              formatter: (w: string | undefined) => {
                if (w === '0') {
                  return '';
                }

                return w || '';
              },
            },
          },
        },
      },
      xaxis: {
        categories: fillIn(
          data.map((item) => {
            // Unassigned
            if (!item.agentId) {
              return 'Unassigned';
            }

            if (!item.agent) {
              return 'Unknown';
            }

            return item.agent.name;
          }),
          10,
          '',
        ),
      },
      legend: {
        position: 'bottom',
      },
      fill: {
        opacity: 1,
      },
    } as ApexOptions,
  };

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="bar"
      height={350}
    />
  );
}

export default function WorkspaceDashboardPage() {
  const workspaceId = useActiveWorkspaceId();
  const website = useActiveWebsite();
  const [date, setDate] = useState<CustomDateSelection>({
    preset: DatePreset.LAST_7D,
    label: 'Last 7d',
    longLabel: 'Last 7 days',
    from: dayjs().subtract(7, 'days'),
    to: dayjs(),
  });

  const response = useQuery(
    async () => {
      if (!workspaceId) {
        return null;
      }

      return api.reports.summary({
        from: date.from?.toISOString(),
        to: date.to?.toISOString(),
      });
    },
    [
      workspaceId,
      date,
    ],
  );

  const featureRequests = useQuery(
    async () => {
      if (!workspaceId) {
        return null;
      }

      return api.featureRequests.list({
        workspaceId,
        from: date.from?.toISOString(),
        to: date.to?.toISOString(),
        $limit: 5,
      });
    },
    [
      workspaceId,
      date,
    ],
  );

  const reports = response.data;

  return (
    <AppPageContent padding="normal" layout="centered">
      <h1>Dashboard</h1>
      <div className="d-flex justify-content-end">
        <RangePickerPopover
          value={date}
          onChange={setDate}
          maxDate={dayjs()}
        />
      </div>
      {
        reports && (
          <div>
            <Row gutter={[16, 16]} className="mt-4">
              <Col span={24} md={8}>
                <Card size="small">
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        backgroundColor: 'var(--color-primary)',
                      }}
                    >
                      <MessageOutlined />
                    </div>
                    <div className="d-flex justify-content-between flex-1 align-items-end">
                      <div>
                        <h3 className="m-0">
                          {reports.totalConversationsCount}
                        </h3>
                        <p className="m-0">
                          Total Conversations
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} md={8}>
                <Card size="small">
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        backgroundColor: 'var(--color-success)',
                      }}
                    >
                      <CheckOutlined />
                    </div>
                    <div className="d-flex justify-content-between flex-1 align-items-end">
                      <div>
                        <h3 className="m-0">
                          {reports.resolvedConversationsCount}
                        </h3>
                        <p className="m-0">
                          Resolved Conversations
                        </p>
                      </div>
                      <p className="m-0 fw-bold">
                        {`${((reports.resolvedConversationsRate || 0) * 100).toFixed(0)}%`}
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={24} md={8}>
                <Card size="small">
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        backgroundColor: 'var(--color-gray-4)',
                      }}
                    >
                      <SendOutlined />
                    </div>
                    <div className="d-flex justify-content-between flex-1 align-items-end">
                      <div>
                        <h3 className="m-0">
                          {reports.totalProactiveConversationsCount}
                        </h3>
                        <p className="m-0">
                          Proactive Conversations
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt-4">
              <Col span={24}>
                <Card size="small">
                  {
                    reports.conversationsByStatusAndAgent && (
                      <ConversationsByStatusAndAgentChart
                        data={reports.conversationsByStatusAndAgent}
                      />
                    )
                  }
                </Card>
              </Col>
            </Row>
            {
              website?.featureRequests?.enabled && (
                <>
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <h2>
                      Top Feature Requests
                    </h2>
                    <Link to="../feature-requests/feature-requests">
                      <Button>See All</Button>
                    </Link>
                  </div>
                  <Row>
                    <Col span={24}>
                      {
                        featureRequests.loading && (
                          <Spinner center />
                        )
                      }
                      {
                        !featureRequests.loading
                        && featureRequests.data
                        && featureRequests.data.items.length > 0 && (
                          <>
                            {
                              featureRequests.data.items.map((request) => (
                                <div key={request._id}>
                                  <FeatureRequestPreviewCard
                                    url={`../feature-requests/${request._id}`}
                                    title={request.title}
                                    createdAt={request.createdAt}
                                    votes={request.votes}
                                    status={request.status}
                                    className="mb-2"
                                  />
                                </div>
                              ))
                            }
                          </>
                        )
                      }
                      {
                        !featureRequests.loading
                        && featureRequests.data
                        && featureRequests.data.items.length === 0 && (
                          <p className="text-center">
                            No feature requests found
                          </p>
                        )
                      }
                    </Col>
                  </Row>
                </>
              )
            }
          </div>
        )
      }
    </AppPageContent>
  );
}
