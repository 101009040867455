import { AxiosInstance } from 'axios';
import Macro, { MacroData } from '@zupport/types/models/Macro';
import Paginated from '@zupport/types/types/Paginated';
import RESTService from './RESTService';

export default class Macros extends RESTService<Macro, MacroData> {
  constructor(axios: AxiosInstance) {
    super(axios, '/macros');
  }

  all() {
    return this._get<Paginated<Macro>>(`${this.endpoint}/all`);
  }
}
